import { isMerlin } from '@/shared/utils/utils';

import { useAppSelector } from '@/app/store/redux-hooks.ts';
import PreloaderIconSynthar from '@/assets/preloader-synthar.svg?react';
import PreloaderIcon from '@/assets/preloader.svg?react';

import './styles.css';

const Index = () => {
  const theme = useAppSelector((state) => state.layout.theme);

  return (
    <div
      style={{
        width: '100%',
        height: 'inherit',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: isMerlin
          ? theme === 'dark'
            ? '#171717'
            : '#fefefe'
          : '#fefefe',
      }}
    >
      {isMerlin ? (
        <PreloaderIcon className="preloader" />
      ) : (
        <PreloaderIconSynthar className="preloader" />
      )}
    </div>
  );
};

export default Index;
