import { useEffect } from 'react';

import { useNotifications } from '@/shared/hooks/useNotification';
import { useUserAuth } from '@/shared/hooks/useUserAuth';
import LoaderWithText from '@/shared/ui/LoaderWithText';

import { loadWrapper } from './payment-styles.css';

export default function PaymentLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { user, token, updateUser, loadingUser, customer, loadingCustomer } =
    useUserAuth();
  useNotifications();

  useEffect(() => {
    if (!token || user) return;
    updateUser();
  }, [token, user]);

  if (loadingUser || (!customer && loadingCustomer)) {
    return (
      <div className={loadWrapper}>
        <LoaderWithText text={'Index user info...'} />
      </div>
    );
  }

  return children;
}
