import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  TOAST_ERROR,
  TOAST_INFO,
  TOAST_SUCCESS,
} from '@/shared/constants/toasts';
import { useGetTranscriptBlocks } from '@/shared/hooks/useGetTranscriptBlocks';
import { useProjectsActions } from '@/shared/hooks/useProjectsActions';
import { useUserAuth } from '@/shared/hooks/useUserAuth';
import {
  EPhase,
  EState,
  type IErrorAction,
  type IProjectAction,
} from '@/shared/types';

export const useNotifications = () => {
  const navigate = useNavigate();
  const { token, updateCustomer } = useUserAuth();
  const { refetchProject, activeProject, addAction } = useProjectsActions();
  const { refetchBlocks } = useGetTranscriptBlocks(activeProject);

  const processData = (event: MessageEvent) => {
    if (!event?.data) return;
    const res: IProjectAction | IErrorAction = JSON.parse(event.data);
    if (!res) return;
    // projects actions
    if (!('code' in res)) {
      processProjectActionData(res);
      return;
    }
    const pathname = window.location.pathname;
    if (res.code === 409) {
      processProjectActionData({
        project: res.project as string,
        state: EState.UPLOAD_ERR,
        phase: EPhase.PROCESSING_FAILED,
        error: res.message,
      });
      return;
    }
    if (res.code === 400) {
      TOAST_ERROR(res?.message || 'Operation payment-rejected!');
      if (pathname?.startsWith('/payment')) {
        navigate('/payment/rejected');
      }
      return;
    }
    if (res.code === 200) {
      updateCustomer(); // update customer after successful action
      if (pathname?.startsWith('/payment')) {
        navigate('/payment/complete');
      }
      TOAST_SUCCESS(res?.message || 'Operation completed successfully!');
      return;
    }
    // show customer message
    if (res?.message) {
      TOAST_INFO(res.message);
    }
  };

  const processProjectActionData = (action: IProjectAction) => {
    addAction(action);
    if (action?.phase?.includes('failed') ?? action?.error) {
      TOAST_ERROR(
        action?.error ??
          `Project ${action?.state || 'operation'} ended with error!`,
      );
    } else {
      TOAST_SUCCESS(
        `Project ${action?.state || 'operation'} ended successfully!`,
      );
    }
    const pathname = window.location.pathname;
    // default project
    if (pathname.startsWith('/dashboard/projects/edit/')) {
      refetchProject();
      refetchBlocks();
    }
    // avatar project
    if (pathname.startsWith('/dashboard/avatar-projects/edit/')) {
      // prettier-ignore
      if (action.state === EState.LIPSYNC || action.state === EState.REGEN_LIPSYNC) {
        updateCustomer(); // update customer data after lipsync in avatar projects
      }
      refetchProject();
    }
  };

  useEffect(() => {
    const socket = new WebSocket(`${import.meta.env.VITE_NOTES_URL}/${token}`);

    // listen for messages
    socket.addEventListener('message', (event) => {
      processData(event);
    });

    return () => {
      socket.close();
    };
  }, [token]);
};
