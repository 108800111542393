import { type Dispatch, type SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';

import { TOAST_WARNING } from '@/shared/constants/toasts';
import { useUserAuth } from '@/shared/hooks/useUserAuth';
import { ETariffName, type IVideoParams } from '@/shared/types';

import { acceptTypes, fileTypes } from '@/widgets/upload-video-modal/constants';

export const useUploadVideoUtils = (
  setVideo: Dispatch<SetStateAction<null | File>>,
  setVideoParams: Dispatch<SetStateAction<null | IVideoParams>>,
  setVideoUploaded: Dispatch<SetStateAction<boolean>>,
  setName?: (name: string) => void,
) => {
  const { customer } = useUserAuth();
  const { t } = useTranslation();
  const validVideoDuration = (video: File): void => {
    const videoInner = document.createElement('video');
    videoInner.preload = 'metadata';
    videoInner.src = URL.createObjectURL(video);

    videoInner.onloadedmetadata = function () {
      window.URL.revokeObjectURL(videoInner.src);
      if (video?.name && setName) {
        setName(video.name.split('.')[0]);
      }
      const videoMetaData = {
        size: video ? (video.size / 1024 ** 2) | 0 : undefined,
        duration: Math.ceil(Number(videoInner.duration) / 60),
        resolution: videoInner.videoWidth * videoInner.videoHeight,
      };

      if (!customer) {
        TOAST_WARNING(t('uploadVideoModal.tariffNotFound'));
        return;
      }

      const {
        customer: {
          subscription: { availableMinutes, tariff },
        },
      } = customer;
      if (
        Math.ceil((+videoInner.duration % 3600) / 60) >
        customer?.customer?.subscription?.availableMinutes
      ) {
        TOAST_WARNING(
          `${t('uploadVideoModal.noLongerThan')} ${availableMinutes} ${t('uploadVideoModal.minutes')}.`,
        );
        return;
      }

      if (
        (tariff.name === ETariffName.BUSINESS_MONTH ||
          tariff.name === ETariffName.BUSINESS_YEAR) &&
        videoInner.duration > 30 * 60
      ) {
        TOAST_WARNING(t('uploadVideoModal.maxTariffMins30'));
        return;
      }

      if (
        (tariff.name === ETariffName.BASIC_MONTH ||
          tariff.name === ETariffName.BASIC_YEAR) &&
        videoInner.duration > 10 * 60
      ) {
        TOAST_WARNING(t('uploadVideoModal.maxTariffMins10'));
        return;
      }

      setVideo(video);
      setVideoParams(videoMetaData);
      setVideoUploaded(true);
    };
  };

  const uploadVideoHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    if (!acceptTypes.includes(file.type)) {
      TOAST_WARNING(`${t('uploadVideoModal.invalidFileFormat')}: ${fileTypes}`);
      return;
    }
    validVideoDuration(file);
  };

  const uploadDragAndDrop = (file: File) => {
    if (!file) return;
    if (!acceptTypes.includes(file.type)) {
      TOAST_WARNING(`${t('uploadVideoModal.invalidFileFormat')}: ${fileTypes}`);
      return;
    }
    validVideoDuration(file);
  };

  return {
    validVideoDuration,
    uploadVideoHandler,
    uploadDragAndDrop,
  };
};
