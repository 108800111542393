import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { MdOutlineTipsAndUpdates } from 'react-icons/md';

import { setShowUpdate } from '@/app/store/features/modalShow.ts';
import { useAppDispatch } from '@/app/store/redux-hooks.ts';
import { wrapper } from '@/features/theme-switcher/ThemeSwitcher.css.ts';

const UpdatesModalBtn = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Tooltip arrow placement={'bottom-end'} title={t('tours.openUpdates')}>
      <button className={wrapper} onClick={() => dispatch(setShowUpdate(true))}>
        <MdOutlineTipsAndUpdates style={{ marginRight: -4 }} />
      </button>
    </Tooltip>
  );
};

export default UpdatesModalBtn;
