import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type AudioVisualiser = {
  playingUrl: null | string;
};

const initialState: AudioVisualiser = {
  playingUrl: null,
};

export const audioVisualiser = createSlice({
  name: 'audioVisualiser',
  initialState,
  reducers: {
    setPlayingUrl: (state, action: PayloadAction<string | null>) => {
      state.playingUrl = action.payload;
    },
  },
});

export const { setPlayingUrl } = audioVisualiser.actions;
export default audioVisualiser.reducer;
