import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  type FetchResult,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Observable } from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { Mutex } from 'async-mutex';
import { GraphQLError } from 'graphql';
import Cookies from 'js-cookie';
import { type ILogObj, Logger } from 'tslog';

import { TOAST_ERROR } from '@/shared/constants/toasts';
import { REFRESH_TOKEN } from '@/shared/graphql/mutations/refreshToken';

const log = new Logger<ILogObj>();
const mutex = new Mutex();

export const authLink = setContext((_, { headers }) => {
  const token = Cookies.get('token');
  return {
    headers: {
      ...headers,
      Authorization: token ? `JWT ${token}` : '',
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        // TODO: switch err.message to err.code
        switch (err.message) {
          case "Sorry, it seems you're not authorized.":
          case "Sorry, it seems you don't have rights to this action.":
            // ignore 401 error for a refresh request
            if (operation.operationName === 'refreshToken' || mutex.isLocked())
              return;

            // eslint-disable-next-line no-case-declarations
            const observable = new Observable<FetchResult<never>>(
              (observer) => {
                // used an anonymous function for using an async function
                (async () => {
                  // await mutex.waitForUnlock();
                  const release = await mutex.acquire();
                  try {
                    const accessToken = await refreshToken();

                    if (!accessToken) {
                      throw new GraphQLError('Empty AccessToken');
                    }

                    // Retry the failed request
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    };

                    forward(operation).subscribe(subscriber);
                  } catch (err) {
                    observer.error(err);
                  } finally {
                    release();
                  }
                })();
              },
            );

            mutex.waitForUnlock();
            return observable;
        }
      }
    }

    if (networkError) {
      TOAST_ERROR(`Network error! ${networkError}`, 'errNetwork');
      log.silly(`[Network error]: ${networkError}`);
    }
  },
);

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_SERVER_URI,
});

const uploadLink = createUploadLink({
  uri: import.meta.env.VITE_GRAPHQL_SERVER_URI,
});

export const httpClient = new ApolloClient({
  ssrMode: false,
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

export const uploadClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([errorLink, authLink, uploadLink]),
});

const refreshToken = async () => {
  const token = Cookies.get('refreshToken');
  if (!token) {
    throw new Error(undefined);
  }
  try {
    const res = await httpClient.mutate({
      mutation: REFRESH_TOKEN,
      variables: {
        refreshToken: token,
      },
    });

    if (res?.data?.refreshToken?.success) {
      const accessToken = res.data?.refreshToken?.token?.token;
      const refreshToken = res.data?.refreshToken?.refreshToken?.token;
      if (!accessToken || !refreshToken) {
        throw new Error(undefined);
      }
      Cookies.set('token', accessToken, { expires: 365 });
      Cookies.set('refreshToken', refreshToken, { expires: 365 });
      return accessToken;
    } else {
      throw new Error(undefined);
    }
  } catch (err) {
    TOAST_ERROR('User confirmation error! Redirect to login', 'errUpdateToken');
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    throw err;
  }
};
