import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { isMerlin } from '@/shared/utils/utils';

import { useAppSelector } from '@/app/store/redux-hooks';

const Toast = () => {
  const theme = useAppSelector((state) => state.layout.theme);

  return (
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme={isMerlin ? theme : 'light'}
    />
  );
};

export default Toast;
