export enum Direction {
  Left = 'left',
  Right = 'right',
}

export type IProject = {
  id: string;
  duration: number;
  created: string;
  title: string;
  video: string;
  srt: string;
  phase: EPhase;
  state: EState;
  avatar: boolean;
  isUploaded: boolean;
  avatarBody?: IAvatarBody;
  lipsyncVideo: string;
  withBackgroundSounds: boolean | null;
  description: string;
  dubVideo: string;
  preview: string;
  originalLang: ILang;
  dubbingOutdated: boolean;
  lipsyncOutdated: boolean;
  lipsyncProgress: string;
  mergedAudio: string;
  synthLang: ILang;
  metaFile: string;
  users: IUserInProject[];
  transcript: {
    blocks: {
      totalCount: number;
      pageInfo: {
        hasNextPage: boolean;
        hasPreviousPage: boolean;
      };
      edges: Array<{ node: ITransBlock }>;
    };
  };
  speakers: ISpeaker[];
};

export type IAvatarBody = {
  id: string;
  text: string;
  synthAudio: string;
  audio: string;
  speaker: ISpeaker;
};

export type ILang = {
  code: string;
  name: string;
};

export type IUserInProject = {
  id: string;
  email: string;
  username: string;
};

export type IAuthUser = {
  email: string;
  id: string;
  language: ELocales;
  username: string;
  verified: boolean;
  isDemo: boolean;
  demoLimit: number;
};

export type ICustomer = {
  wasTrial: boolean;
  cards: ICard[];
  subscription: IUserSubscription;
  bills: {
    edges: Array<{
      node: IPaymentItem;
    }>;
    totalCount: number;
    pageInfo: {
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type IUserSubscription = {
  id: string;
  availableMinutes: number;
  isActive: boolean;
  card: ICard | null;
  renewalDate: string;
  tariff: ITariff;
  paymentRequired: boolean;
};

export type ICard = {
  id: string;
  brand: string;
  country: string;
  expMonth: number;
  expYear: number;
  last4: string;
};
export type ITariff = {
  id: string;
  name: ETariffName;
  virtualAssistant: boolean;
  humanAssistant: boolean;
};
export enum ETariffName {
  FREE = 'Free',
  BASIC_MONTH = 'Basic (month)',
  BASIC_YEAR = 'Basic (year)',
  BUSINESS_MONTH = 'Business (month)',
  BUSINESS_YEAR = 'Business (year)',
  ENTERPRISE = 'Enterprise',
}

export type ITransBlock = {
  id: string;
  text: string;
  endTime: string;
  startTime: string;
  words: IWord[];
  translation: ITranslation;
  speaker: ISpeakersBlock;
};

export type IWord = {
  text: string;
  endTime: string;
  startTime: string;
  highlight?: boolean;
  strikethrough?: boolean;
};
export type ITranslation = {
  id: string;
  text: string;
  scaledSynthAudio: null | string;
  mismatch: null | string;
};
export type ISpeakersBlock = {
  id: string;
  name: string;
};

export type IProjectAction = {
  phase: EPhase;
  project: string;
  state: EState;
  error?: string;
};
export type IErrorAction = {
  code: number;
  message: string;
  project?: string;
};

export type ISpeaker = {
  id: string;
  image: string;
  name: string;
  voices: IVoice[];
  selectedVoice: IVoice;
};

export type IVoice = {
  id: string;
  audio: string;
  name: string;
  voiceId: string;
  language: {
    id: string;
    name: string;
    code: string;
  };
};

export enum EState {
  INITIAL = 'initial', // создание проекта
  PREPARE_VIDEO = 'prepare_video',
  TRANSCRIPT = 'transcript', // транскрибация, включая этап редактирования на клиентской стороне
  SPEAKERS_REFERENCES = 'speakers-references', // вырезка аудио референсов для клонирования голоса из исходного видео
  VOICE_CLONING_AUTO = 'voice-cloning-auto', // автосоздание голосов из транскрибации
  EXTRACT_FACES = 'extract-faces', // извлечение лиц спикеров из исходного видео
  TRANSLATION = 'translation', // перевод блоков, включая этап редактирования на клиентской стороне
  SYNTHESIS = 'synthesis', // синтез содержимого блоков в соответствии с голосами спикеров
  SCALE_SYNTHESIS = 'scale-synthesis', // сжатие/растяжение синтезированных аудио
  DUBBING = 'dubbing', // создание дубляжа видео
  LIPSYNC = 'lipsync', // создание липсинк видео
  REGEN_LIPSYNC = 'regen-lipsync', // создание липсинк видео для одного или несколько блоков
  SAMPLES = 'samples', // получение сэмплов по идентификатору голоса
  VOICE_CLONING_MANUAL = 'voice-cloning-manual', // ручное клонирования голоса на экране спикера
  UPLOAD_SPEAKER_IMAGE = 'upload-speaker-image', // загрузка фото спикера с проверкой лица и последующим кроппингом
  BLOCKS = 'blocks', // только для операций с блоками проекта
  ROLLBACK = 'rollback', // отмена действия
  UPLOAD_ERR = 'upload-err',
}

export enum EPhase {
  ONE = 'none', // только для этапа создания проекта
  SENDING = 'sending', // отправка запроса на сторонний сервис
  SENDING_FAILED = 'sending-failed', // обработка ошибки, появившейся на этапе отправки запроса
  IN_PROGRESS = 'in-progress', // отслеживание прогресса выполнения задачи
  PROCESSING_REPLY = 'processing-reply', // обработка результата запроса на сторонний сервис
  PROCESSING_FAILED = 'processing-failed', // обработка ошибки, появившейся на этапе обработке результата запроса
  WAITING_CLIENT = 'waiting-client', // обработка данных клиентской стороной (пользователем)
}

export type ILangItem = {
  code: string;
  name: string;
};

export type IVideoParams = {
  size: number | undefined;
  duration: number;
  resolution: number;
};

export type IPaymentItem = {
  credits: number;
  datePurchased: string;
  expirationDate: string;
  id: string;
  paymentAmount: string;
  reason: string;
  card: ICard;
};

export type IMismatchItem = {
  endTime: string;
  id: string;
  index: number;
  message: string;
  speaker: string;
  startTime: string;
};

export type IFilters = {
  limit?: number;
  offset?: number;
  search?: string;
  sortBy?: string[];
  avatar?: boolean;
};

export enum ELocales {
  EN = 'en',
  RU = 'ru',
}

export type Tours =
  | 'project'
  | 'avatar'
  | 'project-free-start'
  | 'project-free-empty-speaker'
  | 'project-free-translate'
  | 'project-free-synthes'
  | 'project-free-dubbing'
  | 'project-free-lipsync'
  | 'updates';
