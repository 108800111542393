import { gql } from '@apollo/client';

export const GET_USERS_FOR_PROJ = gql`
  query usersPool {
    usersPool {
      id
      username
      email
      isActive
    }
  }
`;
