import { useTranslation } from 'react-i18next';

import { wrapper } from '@/shared/ui/LoaderWithText/LoaderWithText.css';
import { isMerlin } from '@/shared/utils/utils';
import { cn } from '@/shared/utils/utils';

import PreloaderIconSynthar from '@/assets/preloader-synthar.svg?react';
import PreloaderIcon from '@/assets/preloader.svg?react';

import styles from './LoaderWithText.module.css';

type Props = {
  text?: string;
  className?: string;
  hideText?: boolean;
};

const LoaderWithText = ({ text, className, hideText }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={cn(wrapper, className ?? '')} style={{ margin: 0 }}>
      {isMerlin ? (
        <PreloaderIcon className={styles.preloader} />
      ) : (
        <PreloaderIconSynthar className={styles.preloader} />
      )}
      {!hideText && <span>{text ?? t('general.loading')}</span>}
    </div>
  );
};

export default LoaderWithText;
