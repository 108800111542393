import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Route, Routes } from 'react-router-dom';

import RoutesList from '@/app/router/constants';
import TechWork from '@/pages/tech-work/page.tsx';

const Router = () => {
  const isTechWork = useFeatureIsOn('engineering-works');

  if (isTechWork) {
    return <TechWork />;
  }

  return (
    <Routes>
      {RoutesList.map((route) => (
        <Route key={route.link} path={route.link} element={route.element}>
          {route.children &&
            route.children.map((childrenRoute) => (
              <Route
                key={childrenRoute.link}
                path={childrenRoute.link}
                element={childrenRoute.element}
              />
            ))}
        </Route>
      ))}
    </Routes>
  );
};

export default Router;
