import { type ReactNode } from 'react';

import { outline, wrapper } from '@/shared/ui/Button/Button.css';
import LoaderWithText from '@/shared/ui/LoaderWithText';
import { cn } from '@/shared/utils/utils';

interface Props extends Button {
  isLoading?: boolean;
  children: ReactNode;
  variant?: 'outline';
}

type Button = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const Button = (props: Props) => {
  const { isLoading, children, className, variant, ...rest } = props;

  const getVariant = () => {
    if (variant === 'outline') return outline;
    return '';
  };

  return (
    <button className={cn(wrapper, getVariant(), className)} {...rest}>
      {isLoading ? <LoaderWithText hideText /> : children}
    </button>
  );
};

export default Button;
