import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { LuMoonStar } from 'react-icons/lu';
import { MdOutlineWbSunny } from 'react-icons/md';

import { isMerlin } from '@/shared/utils/utils';

import { switchTheme } from '@/app/store/features/layout';
import { useAppDispatch, useAppSelector } from '@/app/store/redux-hooks';
import { wrapper } from '@/features/theme-switcher/ThemeSwitcher.css';

const ThemeSwitcher = () => {
  const theme = useAppSelector((state) => state.layout.theme);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onCLick = () => {
    dispatch(switchTheme());
  };

  if (!isMerlin) return null; // hide if we use synthar config

  return (
    <Tooltip title={t('tips.switchTheme')} arrow placement={'bottom-end'}>
      <button className={wrapper} onClick={onCLick}>
        {theme === 'light' ? <LuMoonStar /> : <MdOutlineWbSunny />}
      </button>
    </Tooltip>
  );
};

export default ThemeSwitcher;
