import { gql } from '@apollo/client';

export const GET_CUSTOMER = gql`
  query customer($limit: Int, $offset: Int) {
    customer {
      id
      wasTrial
      cards {
        id
        brand
        country
        expMonth
        expYear
        last4
      }
      subscription {
        id
        tariff {
          id
          name
          humanAssistant
          virtualAssistant
        }
        renewalDate
        availableMinutes
        card {
          id
          brand
          country
          expMonth
          expYear
          last4
        }
        isActive
        paymentRequired
      }
      bills(limit: $limit, offset: $offset) {
        totalCount
        edges {
          node {
            id
            datePurchased
            expirationDate
            credits
            paymentAmount
            reason
            card {
              id
              brand
              country
              expMonth
              expYear
              last4
            }
          }
        }
      }
    }
  }
`;

export const GET_CUSTOMER_BILL_SET_COUNT = gql`
  query customer {
    customer {
      id
      bills {
        totalCount
      }
    }
  }
`;
