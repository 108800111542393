import Skeleton from '@/shared/ui/Skeleton';

const SkeletonSubscriptions = () => {
  return (
    <div
      style={{
        width: '100%',
        padding: '30px',
        display: 'flex',
        flexDirection: 'column',
        gap: '1em',
        overflowY: 'auto',
      }}
    >
      <Skeleton width={'200px'} height={'20px'} />
      <Skeleton height={'78px'} />
      <Skeleton height={'193px'} />
      <div style={{ display: 'flex', gap: '1em' }}>
        <Skeleton height={'114px'} />
        <Skeleton height={'114px'} />
      </div>
    </div>
  );
};

export default SkeletonSubscriptions;
