import { type IFormData } from '@/widgets/upload-video-modal/types';

export const validLinks: string[] = [
  'youtube.com',
  'youtu.be',
  'tiktok.com',
  'instagram.com',
  'drive.google.com',
  'disk.yandex.ru',
];

export const formDataInitital: IFormData = {
  title: '',
  text: '',
  videoLink: '',
  withBackgroundSounds: false,
};

export const acceptTypes = 'video/mp4, video/quicktime, video/mov';
export const fileTypes = 'MP4, MOV';
