import { useLazyQuery, useQuery } from '@apollo/client';
import Cookies from 'js-cookie';

import { TOAST_INFO } from '@/shared/constants/toasts';
import { GET_CUSTOMER } from '@/shared/graphql/getters/getCustomer';
import { GET_ME } from '@/shared/graphql/getters/getMe';
import {
  type ETariffName,
  type IAuthUser,
  type ICustomer,
} from '@/shared/types';

import {
  setBlocksPerPage,
  setProjectPage,
  setRefetchProjs,
  setUser,
} from '@/app/store/features/authUser';
import { useAppDispatch, useAppSelector } from '@/app/store/redux-hooks.ts';

type BillsPayload = {
  limit: number;
  offset: number;
};

export const useUserAuth = (speakerPayload?: BillsPayload) => {
  const { user, refetchProjsValue, blocksPerPage, projectPage } =
    useAppSelector((state) => state.authUser);
  const dispatch = useAppDispatch();
  const [getMe, { loading }] = useLazyQuery(GET_ME, {
    fetchPolicy: 'no-cache',
  });
  const {
    data: customer,
    refetch,
    loading: loadingCustomer,
  } = useQuery<{ customer: ICustomer }>(GET_CUSTOMER, {
    variables: {
      limit: speakerPayload?.limit ?? 10,
      offset: speakerPayload?.offset ?? 0,
    },
  });

  const getToken = (key: string) => {
    const value = Cookies.get(key);
    if (!value || value === 'undefined') return null;
    return value;
  };

  const setToken = (value: string) => {
    Cookies.set('token', value, { expires: 365 });
  };
  const setRefresh = (value: string) => {
    Cookies.set('refreshToken', value, { expires: 365 });
  };
  const deleteToken = () => Cookies.remove('token');
  const deleteRefresh = () => Cookies.remove('refreshToken');

  const updateUser = () => {
    if (loading) return;
    getMe().then((res) => {
      if (res?.data?.me) {
        dispatch(
          setUser({
            ...res.data.me,
            verified: res.data.me.status.verified,
          }),
        );
      }
      return;
    });
  };

  const checkTariff = (tariffName: ETariffName) => {
    if (customer?.customer?.subscription?.tariff?.name === tariffName) {
      TOAST_INFO(`This action is not available with ${tariffName} tariff!`);
      return true;
    }
    return false;
  };

  return {
    user,
    refetchProjsValue,
    token: getToken('token'),
    refreshToken: getToken('refreshToken'),
    customer,
    blocksPerPage,
    projectPage,
    loadingUser: loading,
    loadingCustomer,
    setUser: (value: null | IAuthUser) => dispatch(setUser(value)),
    setRefetchProjs: () => dispatch(setRefetchProjs()),
    setToken,
    setRefresh,
    deleteToken,
    deleteRefresh,
    updateUser,
    updateCustomer: async () => await refetch(),
    setProjectPage: (value: number) => dispatch(setProjectPage(value)),
    setBlocksPerPage: (value: number) => dispatch(setBlocksPerPage(value)),
    checkTariff,
  };
};
