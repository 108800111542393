import { useEffect } from 'react';

import { ApolloProvider } from '@apollo/client';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { type ILogObj, Logger } from 'tslog';

import { httpClient } from '@/shared/constants/apolloClients';
import Toast from '@/shared/ui/Toast';

import store, { persistedStore } from '@/app/store';
import '@/app/styles/global.css';
import ThemeLayout from '@/widgets/theme-layout';

const log = new Logger<ILogObj>();

const growthbook = new GrowthBook({
  apiHost: 'https://gb.merlinclone.com',
  clientKey: import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: import.meta.env.DEV,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    log.info('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.variationId,
    });
  },
});

type Props = {
  children: React.ReactNode;
};

export function Providers({ children }: Props) {
  useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.loadFeatures();
  }, []);

  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistedStore}>
          <ApolloProvider client={httpClient}>
            <GrowthBookProvider growthbook={growthbook}>
              <ThemeLayout>
                <div id={'modal-portal-wrapper'} />
                {/* notifications */}
                <Toast />
                {/* children components */}
                {children}
              </ThemeLayout>
            </GrowthBookProvider>
          </ApolloProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}
