import { gql } from '@apollo/client';

export const GET_TRANSCRIPT_BLOCKS = gql`
  query transcriptBlocks($projectId: ID!, $offset: Int, $limit: Int) {
    transcriptBlocks(projectId: $projectId, offset: $offset, limit: $limit) {
      edges {
        cursor
        node {
          id
          text
          startTime
          endTime
          words
          translation {
            id
            text
            mismatch
            minDuration
            maxDuration
            scaledSynthAudio
          }
          speaker {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_TRANSCRIPT_BLOCKS_PAGES_INFO = gql`
  query transcriptBlocks($projectId: ID!) {
    transcriptBlocks(projectId: $projectId) {
      totalCount
    }
  }
`;
