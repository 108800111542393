import { gql } from '@apollo/client';

export const GET_ME = gql`
  query me {
    me {
      id
      email
      username
      isDemo
      language
      demoLimit
      status {
        verified
      }
    }
  }
`;
