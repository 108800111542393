import { gql } from '@apollo/client';

export const CREATE_PROJECT = gql`
  mutation createProject(
    $video: Upload
    $title: String!
    $description: String
    $originalLang: String!
    $synthLang: String!
    $videoLink: String
    $withBackgroundSounds: Boolean!
    $usersEmails: [String!]
    $videoDuration: Int
    $videoSize: Int
    $videoResolution: Int
    $avatar: Boolean!
  ) {
    createProject(
      input: {
        title: $title
        description: $description
        originalLang: $originalLang
        synthLang: $synthLang
        usersEmails: $usersEmails
        video: $video
        withBackgroundSounds: $withBackgroundSounds
        videoLink: $videoLink
        videoDuration: $videoDuration
        videoSize: $videoSize
        videoResolution: $videoResolution
        avatar: $avatar
      }
    ) {
      __typename
      ... on CreateProject {
        project {
          id
          title
          description
          originalLang {
            code
            name
          }
          synthLang {
            code
            name
          }
          users {
            id
            username
            email
          }
        }
      }
      ... on CreateProjectError {
        message
      }
      ... on PermissionError {
        message
      }
    }
  }
`;
