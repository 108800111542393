import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { type IProject, type IProjectAction } from '@/shared/types';

type Initial = {
  data: null | IProjectAction;
  activeProject: null | IProject;
  videoUploadDuration: null | number;
  isActualText: boolean;
  invalidBlock: {
    id: string;
    idx: number;
  } | null;
};

const initialState: Initial = {
  data: null,
  activeProject: null,
  videoUploadDuration: null,
  isActualText: true,
  invalidBlock: null,
};

export const projectsActions = createSlice({
  name: 'projectsActions',
  initialState,
  reducers: {
    addAction: (state, action: PayloadAction<IProjectAction>) => {
      state.data = action.payload;
    },
    removeAction: (state) => {
      state.data = null;
    },
    setVideoUploadDuration: (state, action: PayloadAction<null | number>) => {
      state.videoUploadDuration = action.payload;
    },
    setActiveProject: (state, action: PayloadAction<IProject | null>) => {
      state.activeProject = action.payload;
    },
    changeActiveProject: (state, action: PayloadAction<Partial<IProject>>) => {
      if (!state.activeProject) return;
      state.activeProject = { ...state.activeProject, ...action.payload };
    },
    setIsActualText: (state, action: PayloadAction<boolean>) => {
      state.isActualText = action.payload;
    },
    setInvalidBlock: (
      state,
      action: PayloadAction<Initial['invalidBlock'] | null>,
    ) => {
      state.invalidBlock = action.payload;
    },
  },
});

export const {
  addAction,
  removeAction,
  setActiveProject,
  changeActiveProject,
  setIsActualText,
  setInvalidBlock,
  setVideoUploadDuration,
} = projectsActions.actions;
export default projectsActions.reducer;
