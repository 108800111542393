import Skeleton from '@/shared/ui/Skeleton';

const SkeletonAvatars = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        padding: '1em 0',
        display: 'flex',
        flexDirection: 'column',
        gap: '1em',
      }}
    >
      <header
        style={{
          marginBottom: '.75em',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Skeleton width={'90px'} height={'30px'} />

        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <Skeleton width={'150px'} height={'20px'} pill />
          <Skeleton width={'30px'} height={'30px'} pill />
          <Skeleton width={'30px'} height={'30px'} pill />
        </div>
      </header>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '.75em',
        }}
      >
        <Skeleton width={'250px'} height={'38px'} pill />
        <Skeleton width={'250px'} height={'38px'} pill />
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: 20,
          overflowY: 'auto',
          marginTop: 8,
          alignItems: 'start',
        }}
      >
        <Skeleton width={'100%'} height={'220px'} />
        <Skeleton width={'100%'} height={'220px'} />
        <Skeleton width={'100%'} height={'220px'} />
        <Skeleton width={'100%'} height={'220px'} />
        <Skeleton width={'100%'} height={'220px'} />
      </div>
    </div>
  );
};

export default SkeletonAvatars;
