import { type ReactNode } from 'react';

import { isMerlin } from '@/shared/utils/utils';

import { useAppSelector } from '@/app/store/redux-hooks.ts';
import { merlinThemeClass } from '@/app/styles//merlin-theme.css';
import { syntharThemeClass } from '@/app/styles//synthat-theme.css';
import { merlinThemeLightClass } from '@/app/styles/merlin-theme-light.css';

const ThemeLayout = ({ children }: { children: ReactNode }) => {
  const theme = useAppSelector((state) => state.layout.theme);

  return (
    <div
      className={
        isMerlin
          ? theme === 'dark'
            ? merlinThemeClass
            : merlinThemeLightClass
          : syntharThemeClass
      }
    >
      {children}
    </div>
  );
};

export default ThemeLayout;
