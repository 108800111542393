import Skeleton from '@/shared/ui/Skeleton';

const SkeletonAvatarEdit = () => {
  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <header
        style={{
          marginBottom: '1.75em',
          padding: '1em 0 0',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1em',
        }}
      >
        <Skeleton width={'110px'} height={'30px'} />

        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <Skeleton width={'40px'} height={'40px'} pill />
          <Skeleton width={'40px'} height={'40px'} pill />
          <Skeleton width={'40px'} height={'40px'} pill />
        </div>

        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <Skeleton width={'30px'} height={'30px'} pill />
          <Skeleton width={'30px'} height={'30px'} pill />
          <Skeleton width={'30px'} height={'30px'} pill />
        </div>
      </header>

      <div style={{ flex: 1, display: 'flex', gap: 20 }}>
        <div
          style={{
            width: '40%',
            height: 'inherit',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Skeleton
            width={'100%'}
            height={'50px'}
            styles={{ marginBottom: 15 }}
          />

          <Skeleton
            width={'100%'}
            height={'250px'}
            styles={{ marginBottom: 15 }}
          />

          <div style={{ display: 'flex', gap: 10 }}>
            <Skeleton width={'100%'} height={'40px'} pill />
            <Skeleton width={'100%'} height={'40px'} pill />
          </div>

          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 10,
            }}
          >
            <div style={{ display: 'flex', gap: 10 }}>
              <Skeleton width={'40px'} height={'40px'} pill />
              <Skeleton styles={{ flex: 1 }} height={'40px'} pill />
            </div>
          </div>
        </div>
        <div
          style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 10 }}
        >
          <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
            <Skeleton width={'100%'} height={'50px'} />
          </div>
          <Skeleton width={'100%'} height={'35px'} pill />
          <Skeleton width={'100%'} height={'35px'} pill />
          <Skeleton width={'100%'} height={'35px'} pill />
          <Skeleton width={'100%'} height={'35px'} pill />
          <Skeleton width={'100%'} height={'35px'} pill />
          <Skeleton width={'100%'} height={'35px'} pill />
        </div>
      </div>
    </div>
  );
};

export default SkeletonAvatarEdit;
