import { useTranslation } from 'react-i18next';

import { useUserAuth } from '@/shared/hooks/useUserAuth';

import LangSelect from '@/features/lang-select';
import ThemeSwitcher from '@/features/theme-switcher';
import UpdatesModalBtn from '@/features/updates-modal-btn';

import {
  minsText,
  rightWrapper,
  titleText,
  wrapper,
} from './DashboardHeader.css';

type Props = {
  title: string;
};

const DashboardHeader = ({ title }: Props) => {
  const { customer } = useUserAuth();
  const { t } = useTranslation();

  const getMins = () => {
    if (
      !customer ||
      isNaN(Number(customer?.customer?.subscription?.availableMinutes))
    ) {
      return null;
    }
    return customer?.customer?.subscription?.availableMinutes;
  };

  return (
    <div className={wrapper}>
      <h3 className={titleText} data-testid="sidebar-title">
        {title}
      </h3>
      <div className={rightWrapper}>
        {!!getMins() && (
          <p className={minsText}>
            <span>{t('footerProfile.availableMins')}:</span> {getMins()}{' '}
          </p>
        )}
        <UpdatesModalBtn />
        <ThemeSwitcher />
        <LangSelect />
      </div>
    </div>
  );
};

export default DashboardHeader;
