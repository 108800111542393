import { useLayoutEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useUserAuth } from '@/shared/hooks/useUserAuth.ts';

const Home = () => {
  const { token, refreshToken } = useUserAuth();

  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (token && refreshToken) {
      navigate('/dashboard/projects');
    } else {
      navigate('/sign-in');
    }
  }, []);

  return null;
};

export default Home;
