import { useQuery } from '@apollo/client';

import { GET_ONE_PROJECT } from '@/shared/graphql/getters/getOneProject';
import {
  EPhase,
  EState,
  type IProject,
  type IProjectAction,
} from '@/shared/types';

import {
  addAction,
  changeActiveProject,
  removeAction,
  setActiveProject,
  setInvalidBlock,
  setIsActualText,
} from '@/app/store/features/projectsActions';
import { useAppDispatch, useAppSelector } from '@/app/store/redux-hooks';

interface IProjectActionPayload
  extends Omit<IProjectAction, 'phase' | 'state' | 'project'> {
  state?: EState;
  project?: string;
}

export const useProjectsActions = () => {
  const dispatch = useAppDispatch();
  const activeProject = useAppSelector(
    (state) => state.projectsActions.activeProject,
  );
  const invalidBlock = useAppSelector(
    (state) => state.projectsActions.invalidBlock,
  );
  const isActualText = useAppSelector(
    (state) => state.projectsActions.isActualText,
  );
  const { refetch: refetchProject } = useQuery(GET_ONE_PROJECT, {
    variables: {
      id: activeProject?.id,
    },
    skip: !activeProject?.id,
  });

  const setLoad = (value: boolean) => {
    if (!activeProject) return;
    dispatch(
      changeActiveProject({
        phase: value ? EPhase.IN_PROGRESS : EPhase.WAITING_CLIENT,
        state: EState.BLOCKS,
      }),
    );
  };

  return {
    activeProject,
    isActualText,
    invalidBlock,
    addAction: (value: IProjectAction) => dispatch(addAction(value)),
    setActiveProject: (value: null | IProject) =>
      dispatch(setActiveProject(value)),
    changeActiveProject: (value: Partial<IProject>) =>
      dispatch(changeActiveProject(value)),
    setIsActualText: (value: boolean) => dispatch(setIsActualText(value)),
    setLoad,
    refetchProject,
    setInvalidBlock: (value: null | { id: string; idx: number }) =>
      dispatch(setInvalidBlock(value)),
  };
};

export const useSavedProjectsActions = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.projectsActions.data);

  const findAction = (
    actionFind: IProjectActionPayload,
  ): false | IProjectAction => {
    if (!data) return false;
    if (
      (actionFind.project ? data.project === actionFind.project : true) &&
      (actionFind?.state != null ? data.state === actionFind.state : true) &&
      (data.phase === EPhase.PROCESSING_FAILED ||
        data.phase === EPhase.SENDING_FAILED ||
        data.phase === EPhase.WAITING_CLIENT)
    )
      return data;
    return false;
  };

  return {
    actionsData: data,
    removeAction: () => dispatch(removeAction()),
    findAction,
  };
};
