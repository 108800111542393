import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { isMerlin } from '@/shared/utils/utils';
import { cn } from '@/shared/utils/utils';

import DashboardHeader from '@/widgets/dashboard-header/DashboardHeader.tsx';

import { pageContainer, pageInfo, tab, tabActive, tabs } from './layout.css';

const links = [
  {
    link: '/subscriptions/overview',
    text: 'overview',
    tabName: 'overview',
  },
  {
    link: '/subscriptions/billing-info',
    text: 'billingInfo',
    tabName: 'billing-info',
    hidden: isMerlin,
  },
  {
    link: '/subscriptions/payment-history',
    text: 'paymentHistory',
    tabName: 'payment-history',
  },
];

export default function SubscribtionsLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [tabName, setTabName] = useState(pathname?.split('/')[2]);
  const { t } = useTranslation();

  const isActive = (tab: string) => (tabName === tab ? tabActive : '');

  useEffect(() => {
    setTabName(pathname?.split('/')[2]);
    if (pathname === '/subscriptions') {
      navigate('/subscriptions/overview');
    }
  }, [pathname]);

  return (
    <section className={pageContainer}>
      <DashboardHeader title={t('subscriptions.subscriptions')} />
      <div className={pageInfo}>
        <div className={tabs} data-testid="subscription-tabs">
          {links.map(({ link, hidden, text, tabName: tabIn }) => {
            if (hidden) return null;
            return (
              <Link key={link} to={link} className={cn(tab, isActive(tabIn))}>
                {t('subscriptions.' + text)}
              </Link>
            );
          })}
        </div>
        <section style={{ flex: 1 }}>{children}</section>
      </div>
    </section>
  );
}
