import {
  input,
  invalid as invalidStyles,
  label as labelStyle,
  wrapper,
} from '@/shared/ui/FormControl/FormControl.css';
import { cn } from '@/shared/utils/utils';

interface Props extends Input {
  label?: string;
  invalid?: boolean;
  labelBg?: string;
}

type Input = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const FormControl = (props: Props) => {
  const { label, labelBg, invalid: invalidFlag, ...rest } = props;

  return (
    <div className={wrapper}>
      {label && (
        <label
          style={labelBg ? { background: labelBg } : undefined}
          className={labelStyle}
        >
          {label}
        </label>
      )}
      <input
        className={cn(input, invalidFlag ? invalidStyles : '', rest.className)}
        {...(rest as Input)}
      />
    </div>
  );
};

export default FormControl;
