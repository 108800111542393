import { type CSSProperties } from 'react';

import { vars } from '@/app/styles/contract.css.ts';

export const customStyles: { overlay: CSSProperties; content: CSSProperties } =
  {
    overlay: {
      position: 'absolute',
      right: '0',
      top: '0',
      bottom: '0',
      left: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '9',
    },
    content: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      width: 'fit-content',
      minWidth: '300px',
      minHeight: '200px',
      maxHeight: '95vh',
      padding: '40px 50px 30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'column' as const,
      gap: '10px',
      backgroundColor: vars.color.secondaryBg,
      borderRadius: '10px',
      border: '0',
      overflowY: 'auto',
    },
  };
