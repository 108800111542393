import LogoIcon from '@/shared/ui/LogoIcon/LogoIcon';
import { isMerlin } from '@/shared/utils/utils';

import { useAppSelector } from '@/app/store/redux-hooks.ts';
import DarkMerlinBg from '@/assets/bg-sign-merlin.svg?react';
import {
  bg,
  content,
  logo,
  wrapper,
} from '@/pages/sign-layout/SignLayout.css.ts';

const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  const theme = useAppSelector((state) => state.layout.theme);

  return (
    <div className={wrapper}>
      <div className={content}>
        <div className={logo}>
          <LogoIcon />
        </div>
        {children}
      </div>

      {isMerlin && theme === 'dark' ? (
        <div className={bg}>
          <DarkMerlinBg />
        </div>
      ) : (
        <div
          className={bg}
          style={{
            backgroundImage: `url(/backgrounds/bg-sign-${isMerlin ? 'merlin' : 'synthar'}.jpg)`,
          }}
        />
      )}
    </div>
  );
};

export default AuthLayout;
