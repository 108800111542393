import { useEffect } from 'react';

import { useMutation } from '@apollo/client';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

import { CHANGE_LANGUAGE } from '@/shared/graphql/mutations/changeLanguage.ts';
import { useUserAuth } from '@/shared/hooks/useUserAuth.ts';
import { ELocales } from '@/shared/types';
import { isMerlin } from '@/shared/utils/utils';

import { wrapper } from '@/features/lang-select/ui/lang-select.css';

const LangSelect = () => {
  const [changeLang] = useMutation(CHANGE_LANGUAGE);
  const { user } = useUserAuth();
  const { t, i18n } = useTranslation();

  const onChangeServer = async (value: ELocales) => {
    if (!user?.id) return;
    await changeLang({
      variables: {
        input: { userId: user.id, language: value },
      },
    });
  };

  const onClick = async () => {
    const locale = i18n.language === ELocales.EN ? ELocales.RU : ELocales.EN;
    await i18n.changeLanguage(locale);
    await onChangeServer(locale);
  };

  useEffect(() => {
    if (!isMerlin) return;
    document.documentElement.lang = i18n.language ?? ELocales.EN;
  }, [i18n.language]);

  if (!isMerlin) return;

  return (
    <Tooltip title={t('tips.switchLang')} arrow placement={'bottom-start'}>
      <button className={wrapper} onClick={onClick}>
        {(i18n.language ?? ELocales.EN)?.toUpperCase()}
      </button>
    </Tooltip>
  );
};

export default LangSelect;
