import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { FaAngleRight } from 'react-icons/fa';

import { AVAILABLE_LANGUAGES } from '@/shared/graphql/getters/getLanguages';
import type { ILangItem } from '@/shared/types';

import { type IUsersSelectWrapperProps } from '@/widgets/upload-video-modal/types';
import {
  arrowIconItem,
  formatItem,
  optionLang,
  selectBlock,
  selectLang,
} from '@/widgets/upload-video-modal/UploadVideoModal.css';

const LangsSelect = (props: IUsersSelectWrapperProps) => {
  const { formData, setFormData, fetchSkip, hideOriginal } = props;

  const { t } = useTranslation();
  const { data: projLangs } = useQuery(AVAILABLE_LANGUAGES, {
    skip: fetchSkip,
  });

  return (
    <div className={selectBlock}>
      <div className={formatItem}>
        <p style={hideOriginal ? { opacity: 0.25 } : undefined}>
          {t('uploadVideoModal.originalLang')}
        </p>
        <img
          src={`/svgFlags/${formData.originalLang}.svg`}
          alt="Original language"
          width={20}
          height={20}
          style={hideOriginal ? { opacity: 0.5 } : undefined}
        />
        <select
          disabled={hideOriginal}
          title="Select Language"
          className={selectLang}
          value={formData.originalLang}
          onChange={(e) => {
            setFormData({ ...formData, originalLang: e.target.value });
          }}
        >
          {projLangs?.availableLanguages ? (
            projLangs.availableLanguages.map((elem: ILangItem) => (
              <option key={elem.code} value={elem.code} className={optionLang}>
                {elem.name}
              </option>
            ))
          ) : (
            <option>{t('general.loading')}</option>
          )}
        </select>
      </div>

      <FaAngleRight className={arrowIconItem} />

      <div className={formatItem}>
        <p>{t('uploadVideoModal.synthLang')}</p>
        <img
          src={`/svgFlags/${formData.synthLang}.svg`}
          alt="Synthesis language"
          width={20}
          height={20}
        />
        <select
          title="Select Language"
          className={selectLang}
          value={formData.synthLang || 'Index...'}
          onChange={(e) => {
            setFormData({ ...formData, synthLang: e.target.value });
          }}
        >
          {projLangs?.availableLanguages ? (
            projLangs.availableLanguages.map((elem: ILangItem) => (
              <option key={elem.code} value={elem.code} className={optionLang}>
                {elem.name}
              </option>
            ))
          ) : (
            <option>{t('general.loading')}</option>
          )}
        </select>
      </div>
    </div>
  );
};

export default LangsSelect;
