import { lazy, Suspense } from 'react';

import { Outlet } from 'react-router-dom';

import { isMerlin } from '@/shared/utils/utils';

import SuspenseLayout from '@/features/suspense';
import SkeletonAvatarEdit from '@/pages/avatar-edit/skeleton.tsx';
import SkeletonAvatars from '@/pages/avatars/skeleton.tsx';
import Home from '@/pages/home';
import PaymentLayout from '@/pages/payment-layout/layout.tsx';
import SkeletonProjectEdit from '@/pages/project-edit/skeleton.tsx';
import SkeletonProjects from '@/pages/projects/skeleton.tsx';
import SidebarLayout from '@/pages/sidebar-layout';
import AuthLayout from '@/pages/sign-layout/layout.tsx';
import SubscribtionsLayout from '@/pages/subscriptions-layout/layout.tsx';
import SkeletonSubscriptions from '@/pages/subscriptions-layout/skeleton.tsx';

type RouterItem = {
  element: JSX.Element;
  link: string;
  children?: RouterItem[];
};

const NotFound = lazy(() => import('@/pages/not-found'));
const SignIn = lazy(() => import('@/pages/sign-in'));
const SignUp = lazy(() => import('@/pages/sign-up'));
const PasswordReset = lazy(() => import('@/pages/password-reset'));
const PasswordRecovery = lazy(() => import('@/pages/password-recovery'));
const Projects = lazy(() => import('@/pages/projects'));
const ProjectEdit = lazy(() => import('@/pages/project-edit'));
const Avatars = lazy(() => import('@/pages/avatars'));
const AvatarEdit = lazy(() => import('@/pages/avatar-edit'));
const GoogleToken = lazy(() => import('@/pages/google-token'));
const BillingInfo = lazy(() => import('@/pages/billing-info'));
const SubscriptionsOverview = lazy(() => import('@/pages/overview'));
const PaymentHistory = lazy(() => import('@/pages/payment-history'));
const Prices = lazy(() => import('@/pages/prices'));
const SubscriptionsOverviewMerlin = lazy(
  () => import('@/pages/overview-merlin'),
);
const PaymentHistoryMerlin = lazy(
  () => import('@/pages/payment-history-merlin'),
);
const PricesMerlin = lazy(() => import('@/pages/prices-merlin'));
const PaymentRejected = lazy(() => import('@/pages/payment-rejected'));
const PaymentComplete = lazy(() => import('@/pages/payment-complete'));
const TrialPaymentTarif = lazy(() => import('@/pages/payment-trial-tarif'));
const TrialPaymentAddCardTarif = lazy(
  () => import('@/pages/payment-trial-add-card'),
);
const PaymentBuyMinutes = lazy(() => import('@/pages/payment-buy-minutes'));
const PaymentBuyMinutesAddCard = lazy(
  () => import('@/pages/payment-buy-minutes-add-card'),
);
const PaymentTarif = lazy(() => import('@/pages/payment-tarif'));
const PaymentSavedCard = lazy(() => import('@/pages/payment-tarif-saved-card'));
const PaymentTarifUpdate = lazy(() => import('@/pages/payment-tarif-update'));
const PaymentTarifClientSecret = lazy(
  () => import('@/pages/payment-client-secret'),
);

const RoutesList: RouterItem[] = [
  {
    element: <Home />,
    link: '/',
  },
  {
    element: (
      <Suspense fallback={<SuspenseLayout />}>
        <GoogleToken />
      </Suspense>
    ),
    link: '/google-token',
  },
  {
    element: (
      <AuthLayout>
        <Suspense fallback={<></>}>
          <Outlet />
        </Suspense>
      </AuthLayout>
    ),
    link: '/sign-in',
    children: [
      {
        link: '',
        element: <SignIn />,
      },
      {
        link: 'password-recovery',
        element: <PasswordRecovery />,
      },
    ],
  },
  {
    element: (
      <AuthLayout>
        <Suspense fallback={<></>}>
          <SignUp />
        </Suspense>
      </AuthLayout>
    ),
    link: '/sign-up',
  },
  {
    element: (
      <AuthLayout>
        <Suspense fallback={<></>}>
          <PasswordReset />
        </Suspense>
      </AuthLayout>
    ),
    link: '/password-reset/:token',
  },
  {
    element: (
      <SidebarLayout>
        <Outlet />
      </SidebarLayout>
    ),
    link: '/dashboard',
    children: [
      {
        link: 'projects',
        element: (
          <Suspense fallback={<SkeletonProjects />}>
            <Projects />
          </Suspense>
        ),
      },
      {
        link: 'projects/edit/:id',
        element: (
          <Suspense fallback={<SkeletonProjectEdit />}>
            <ProjectEdit />
          </Suspense>
        ),
      },
      {
        link: 'avatar-projects',
        element: (
          <Suspense fallback={<SkeletonAvatars />}>
            <Avatars />
          </Suspense>
        ),
      },
      {
        link: 'avatar-projects/edit/:id',
        element: (
          <Suspense fallback={<SkeletonAvatarEdit />}>
            <AvatarEdit />
          </Suspense>
        ),
      },
    ],
  },
  {
    link: 'subscriptions',
    element: (
      <SidebarLayout>
        <SubscribtionsLayout>
          <Suspense fallback={<SkeletonSubscriptions />}>
            <Outlet />
          </Suspense>
        </SubscribtionsLayout>
      </SidebarLayout>
    ),
    children: [
      {
        element: <BillingInfo />,
        link: 'billing-info',
      },
      {
        element: isMerlin ? (
          <SubscriptionsOverviewMerlin />
        ) : (
          <SubscriptionsOverview />
        ),
        link: 'overview',
      },
      {
        element: isMerlin ? <PaymentHistoryMerlin /> : <PaymentHistory />,
        link: 'payment-history',
      },
      {
        element: isMerlin ? <PricesMerlin /> : <Prices />,
        link: 'prices',
      },
    ],
  },
  {
    element: (
      <PaymentLayout>
        <Suspense fallback={<SuspenseLayout />}>
          <Outlet />
        </Suspense>
      </PaymentLayout>
    ),
    link: '/payment/',
    children: [
      {
        link: 'rejected',
        element: <PaymentRejected />,
      },
      {
        link: 'complete',
        element: <PaymentComplete />,
      },
      {
        link: 'trial/:tarifId',
        element: isMerlin ? <NotFound /> : <TrialPaymentTarif />,
      },
      {
        link: 'trial/addCard/:tarifId',
        element: isMerlin ? <NotFound /> : <TrialPaymentAddCardTarif />,
      },
      {
        link: 'buyMinutes',
        element: isMerlin ? <NotFound /> : <PaymentBuyMinutes />,
      },
      {
        link: 'buyMinutes/addCard',
        element: isMerlin ? <NotFound /> : <PaymentBuyMinutesAddCard />,
      },
      {
        link: ':tarifId',
        element: isMerlin ? <NotFound /> : <PaymentTarif />,
      },
      {
        link: ':tarifId/:clientSecret',
        element: isMerlin ? <NotFound /> : <PaymentTarifClientSecret />,
      },
      {
        link: ':tarifId/payWithSavedCard',
        element: isMerlin ? <NotFound /> : <PaymentSavedCard />,
      },
      {
        link: ':tarifId/update',
        element: isMerlin ? <NotFound /> : <PaymentTarifUpdate />,
      },
    ],
  },
  {
    element: (
      <Suspense fallback={<SuspenseLayout />}>
        <NotFound />
      </Suspense>
    ),
    link: '*',
  },
];

export default RoutesList;
