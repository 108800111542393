import { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CREATE_PROJECT } from '@/shared/graphql/mutations/createProj';
import { useUserAuth } from '@/shared/hooks/useUserAuth.ts';
import { type IVideoParams } from '@/shared/types';
import Button from '@/shared/ui/Button';
import ModalWindow from '@/shared/ui/ModalWindow';

import { setShowCreate } from '@/app/store/features/modalShow';
import { useAppDispatch, useAppSelector } from '@/app/store/redux-hooks';
import { formDataInitital } from '@/widgets/upload-video-modal/constants';
import { type IFormData } from '@/widgets/upload-video-modal/types';
import LoadingWrapper from '@/widgets/upload-video-modal/ui/LoadingWrapper';
import ProjectInfoWrapper from '@/widgets/upload-video-modal/ui/ProjectInfoWrapper';
import UploadVideo from '@/widgets/upload-video-modal/ui/UploadVideo';
import { noMinsWrapper } from '@/widgets/upload-video-modal/UploadVideoModal.css.ts';

const UploadVideoModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [video, setVideo] = useState<null | File>(null);
  const [videoParams, setVideoParams] = useState<null | IVideoParams>(null);
  const [videoUploaded, setVideoUploaded] = useState<boolean>(false);
  const isAvatar = useAppSelector(
    (state) => state.modalShow.showCreate?.isAvatar,
  );
  const { customer } = useUserAuth();

  const [formData, setFormData] = useState<IFormData>(formDataInitital);

  const [createProject, { loading }] = useMutation(CREATE_PROJECT);

  const showUploadWrapper = () => !videoUploaded && !loading;

  const handleClose = () => {
    if (loading) {
      toast(t('uploadVideoModal.waitProjectLoad'), {
        autoClose: 3000,
        type: 'info',
        toastId: 'wait',
      });
      return;
    }
    dispatch(setShowCreate({ isShow: false }));
  };

  useEffect(() => {
    setFormData(formDataInitital);
  }, []);

  if (customer?.customer?.subscription?.availableMinutes === 0) {
    return (
      <ModalWindow open onClose={handleClose} testId="upload-video-modal">
        <div className={noMinsWrapper}>
          <h3>{t('uploadVideoModal.ops')}</h3>
          <p>
            {customer?.customer?.wasTrial
              ? t('uploadVideoModal.noMinutes')
              : t('uploadVideoModal.noMinutesWithTrial')}
          </p>
          <footer>
            <Button
              variant={'outline'}
              onClick={() => dispatch(setShowCreate({ isShow: false }))}
            >
              {t('general.cancel')}
            </Button>
            <Link to={'/subscriptions/prices'}>
              <Button>{t('uploadVideoModal.toSub')}</Button>
            </Link>
          </footer>
        </div>
      </ModalWindow>
    );
  }

  return (
    <ModalWindow open onClose={handleClose} testId="upload-video-modal">
      {/* upload video screen with buttons and drag&drop */}
      {showUploadWrapper() && (
        <UploadVideo
          avatar={!!isAvatar}
          formData={formData}
          setVideoParams={setVideoParams}
          setVideo={setVideo}
          setVideoUploaded={setVideoUploaded}
          setFormData={setFormData}
        />
      )}

      {videoUploaded && !loading && (
        <ProjectInfoWrapper
          formData={formData}
          video={video}
          createProject={createProject}
          setVideoUploaded={setVideoUploaded}
          setVideo={setVideo}
          setFormData={setFormData}
          videoParams={videoParams}
          avatar={!!isAvatar}
        />
      )}

      {/* uploading video */}
      {loading && <LoadingWrapper />}
    </ModalWindow>
  );
};

export default UploadVideoModal;
