import { useTranslation } from 'react-i18next';

import LogoIcon from '@/shared/ui/LogoIcon/LogoIcon';
import { isMerlin } from '@/shared/utils/utils';

import { logo, text, title, wrapper } from './styles.css';

const TechWork = () => {
  const { t } = useTranslation();

  return (
    <div className={wrapper}>
      <LogoIcon className={logo} />
      <span className={title}>{t('emptyList.techWorkTitle')}</span>
      <p className={text}>
        {t('emptyList.techWorkText')}{' '}
        <span>{isMerlin ? 'Merlin Clone' : 'Synthar'}</span>!
      </p>
    </div>
  );
};

export default TechWork;
