import { useEffect } from 'react';

import { isMerlin } from '@/shared/utils/utils';

export function ExternalLibraries() {
  useEffect(() => {
    // @ts-expect-error - Intercom was initialized
    if (!import.meta.env.PROD || !window?.Intercom) return;
    // @ts-expect-error - Intercom was initialized
    window?.Intercom('update');
    // @ts-expect-error - Intercom was initialized
    window?.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    });
    // @ts-expect-error - Intercom was initialized
  }, [window?.Intercom]);

  if (!import.meta.env.PROD) return null;

  if (!isMerlin) {
    return (
      <>
        <script id="rewardful-queue" async>
          {`(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`}
        </script>

        <script
          async
          id="rewardful-script-loader-with-api-key"
          src={'https://r.wdfl.co/rw.js'}
          data-rewardful={import.meta.env.VITE_REWARDFUL_ID}
        />
      </>
    );
  }

  return null;
}
