import { useQuery } from '@apollo/client';

import {
  GET_TRANSCRIPT_BLOCKS,
  GET_TRANSCRIPT_BLOCKS_PAGES_INFO,
} from '@/shared/graphql/getters/getTranscriptBlocks';
import { useUserAuth } from '@/shared/hooks/useUserAuth';
import { IProject } from '@/shared/types';

export const useGetTranscriptBlocks = (project: IProject | null) => {
  const { projectPage, blocksPerPage } = useUserAuth();
  const skip = !project?.id || project?.avatar || !project.isUploaded;

  const {
    data,
    loading,
    error,
    refetch: refetchBlocks,
  } = useQuery(GET_TRANSCRIPT_BLOCKS, {
    variables: {
      projectId: project?.id,
      offset: projectPage * blocksPerPage || 0,
      limit: blocksPerPage,
    },
    skip,
    fetchPolicy: 'network-only',
  });

  const { data: dataPages, refetch: refetchPagesInfo } = useQuery(
    GET_TRANSCRIPT_BLOCKS_PAGES_INFO,
    { variables: { projectId: project?.id }, skip },
  );

  const refetch = async (skipRefetchPageInfo?: boolean) => {
    await refetchBlocks();
    if (!skipRefetchPageInfo) {
      await refetchPagesInfo();
    }
  };

  return {
    data,
    dataPages,
    loading,
    error,
    refetchBlocks: refetch,
  };
};
