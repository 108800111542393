import { gql } from '@apollo/client';

export const GET_ONE_PROJECT = gql`
  query project($id: ID!) {
    project(id: $id) {
      __typename
      ... on ProjectNode {
        id
        title
        originalLang {
          code
          name
        }
        synthLang {
          code
          name
        }
        duration
        phase
        state
        error
        dubbingOutdated
        lipsyncOutdated
        lipsyncProgress
        preview
        avatar
        isUploaded
        avatarBody {
          id
          text
          synthAudio
          audio
          speaker {
            name
            id
            image
            voices {
              id
              name
              voiceId
              audio
            }
            selectedVoice {
              id
              name
              voiceId
              audio
            }
          }
        }
        video
        lipsyncVideo
        lipsyncVideoWithSub
        dubVideo
        metaFile
        srt
        mergedAudio
        created
        speakers {
          name
          id
          image
          voices {
            id
            name
            voiceId
            audio
          }
          selectedVoice {
            id
            name
            voiceId
            audio
          }
        }
      }
      ... on ProjectQueryError {
        message
      }
    }
  }
`;
