import { useTranslation } from 'react-i18next';

import Button from '@/shared/ui/Button';
import {
  footerBtns,
  text as textStyles,
  title as titleStyles,
} from '@/shared/ui/ConfirmationModal/ConfirmationModal.css';
import ModalWindow from '@/shared/ui/ModalWindow';

type ConfirmationModal = {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  text?: string;
  confirmFn: () => void;
  loading?: boolean;
  zIndex?: number;
  confirmText?: string;
  closeText?: string;
  hideClose?: boolean;
};

const ConfirmationModal = (props: ConfirmationModal) => {
  const {
    onClose,
    isOpen,
    text,
    title,
    confirmFn,
    loading,
    zIndex,
    confirmText,
    closeText,
    hideClose,
  } = props;
  const { t } = useTranslation();

  return (
    <ModalWindow
      styles={{ maxWidth: 550 }}
      open={isOpen}
      onClose={onClose}
      zIndex={zIndex}
      hideClose={hideClose}
    >
      <h4 className={titleStyles}>{title ?? t('dashboard.confirmAction')}</h4>
      {text && <p className={textStyles}>{text}</p>}

      <footer className={footerBtns}>
        <Button variant={'outline'} onClick={onClose}>
          {closeText || t('general.cancel')}
        </Button>

        <Button onClick={confirmFn} disabled={loading} isLoading={loading}>
          {confirmText || t('general.confirm')}
        </Button>
      </footer>
    </ModalWindow>
  );
};

export default ConfirmationModal;
