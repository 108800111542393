import { wrapper } from '@/shared/ui/LogoIcon/LogoIcon.css';
import { isMerlin } from '@/shared/utils/utils';

import LogoIconSynthar from '@/assets/logo-synthar.svg?react';
import LogoIconMC from '@/assets/logo.svg?react';

type Props = {
  className?: string;
};

const LogoIcon = ({ className }: Props) => {
  if (isMerlin) {
    return <LogoIconMC className={`${wrapper} ${className ?? ''}`} />;
  } else {
    return <LogoIconSynthar className={`${wrapper} ${className ?? ''}`} />;
  }
};

export default LogoIcon;
