import { type FormEvent, useState } from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

import { uploadClient } from '@/shared/constants/apolloClients';
import { TOAST_ERROR } from '@/shared/constants/toasts';
import { GET_USERS_FOR_PROJ } from '@/shared/graphql/getters/getUsersForProject';
import { useUserAuth } from '@/shared/hooks/useUserAuth';
import Button from '@/shared/ui/Button';
import FormControl from '@/shared/ui/FormControl';
import FormTextarea from '@/shared/ui/FormTextarea';
import { isMerlin } from '@/shared/utils/utils.ts';

import { setShowCreate } from '@/app/store/features/modalShow';
import { useAppDispatch } from '@/app/store/redux-hooks';
import SelectUsers, { type SelectedUser } from '@/features/select-users';
import {
  type IProjectInfoWrapperProps,
  type IVariables,
} from '@/widgets/upload-video-modal/types';
import LangsSelect from '@/widgets/upload-video-modal/ui/LangsSelect';
import {
  back,
  btnContainer,
  checkContainer,
  projectForm,
} from '@/widgets/upload-video-modal/UploadVideoModal.css';

const ProjectInfoWrapper = (props: IProjectInfoWrapperProps) => {
  const {
    formData,
    setFormData,
    setVideo,
    setVideoUploaded,
    video,
    videoParams,
    createProject,
    avatar,
  } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setRefetchProjs, updateCustomer } = useUserAuth();
  const [langsData, setLangsData] = useState({
    originalLang: isMerlin ? 'ru' : 'en',
    synthLang: isMerlin ? 'en' : 'it',
  });
  const navigate = useNavigate();
  const [selectedUsers, setSelectedUsers] = useState<null | SelectedUser[]>(
    null,
  );

  const { data: usersData, refetch: refetchUsers } =
    useQuery(GET_USERS_FOR_PROJ);

  const handleSendVideo = async (e: FormEvent) => {
    e.preventDefault();

    const variables: IVariables = {
      video,
      title: formData.title,
      description: formData.text,
      originalLang: langsData.originalLang,
      synthLang: langsData.synthLang,
      withBackgroundSounds: !!formData.withBackgroundSounds,
      usersEmails: selectedUsers?.map((el: { email: string }) => el.email),
      videoSize: videoParams?.size ?? 1,
      videoDuration: Math.floor(videoParams?.duration ?? 1),
      videoResolution: videoParams?.resolution,
      avatar,
    };
    if (formData.videoLink) {
      delete variables.video;
      variables.videoLink = formData.videoLink;
    }
    try {
      const data = await createProject({
        client: uploadClient,
        variables,
      });
      if (data?.data?.createProject?.message) {
        throw new Error(data?.data?.createProject?.message);
      }
      // if we have new users we need to refetch userPool query
      if (selectedUsers && usersData) {
        const newUser = selectedUsers
          ?.map((user) => {
            if (
              !usersData.usersPool.find(
                (elem: { email: string }) => elem.email === user.email,
              )
            ) {
              return user;
            } else return null;
          })
          ?.filter((elem) => elem);
        if (newUser) refetchUsers();
      }
      // redirect after adding project to avatar or projects page
      const id = data?.data?.createProject.project.id;
      if (id) {
        navigate(
          avatar
            ? `/dashboard/avatar-projects/edit/${id}`
            : `/dashboard/projects/edit/${id}`,
        );
      } else {
        navigate(avatar ? '/dashboard/avatar-projects' : '/dashboard/projects');
        setRefetchProjs(); // projects update in projects page
      }
    } catch (err) {
      TOAST_ERROR((err as Error)?.message ?? 'Creating project error!');
    } finally {
      setVideoUploaded(false);
      setTimeout(updateCustomer, 1000 * 60);
      dispatch(setShowCreate({ isShow: false }));
    }
  };

  return (
    <>
      <IoMdArrowRoundBack
        onClick={() => {
          setFormData({ ...formData, videoLink: '' });
          setVideo(null);
          setVideoUploaded(false);
        }}
        className={back}
      />

      <SelectUsers
        selected={selectedUsers}
        setSelected={setSelectedUsers}
        data={usersData?.usersPool}
      />

      <form onSubmit={handleSendVideo} className={projectForm} id="send video">
        <FormControl
          label={t('uploadVideoModal.titlePlaceholder')}
          required
          placeholder={t('uploadVideoModal.titlePlaceholder')}
          defaultValue={formData.title}
          onBlur={(e) => {
            setFormData({ ...formData, title: e.target.value });
          }}
        />

        <FormTextarea
          rows={4}
          label={t('uploadVideoModal.descriptionPlaceholder')}
          placeholder={t('uploadVideoModal.descriptionPlaceholder')}
          defaultValue={formData.text}
          onBlur={(e) => {
            setFormData({ ...formData, text: e.target.value });
          }}
        />

        {!avatar && (
          <LangsSelect formData={langsData} setFormData={setLangsData} />
        )}

        {!avatar && (
          <div className={checkContainer}>
            <input
              type={'checkbox'}
              defaultChecked={!!formData.withBackgroundSounds}
              onClick={() => {
                setFormData({
                  ...formData,
                  withBackgroundSounds: !formData.withBackgroundSounds,
                });
              }}
            />
            <label>{t('uploadVideoModal.saveBackgroundSounds')}</label>
          </div>
        )}

        <div className={btnContainer}>
          <Button
            variant={'outline'}
            style={{ flex: 1, padding: 14 }}
            onClick={() => dispatch(setShowCreate({ isShow: false }))}
          >
            {t('general.cancel')}
          </Button>

          <Button type="submit" style={{ flex: 1, padding: 14 }}>
            {t('general.submit')}
          </Button>
        </div>
      </form>
    </>
  );
};

export default ProjectInfoWrapper;
