import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

type ModalShow = {
  isSidebarShow: boolean;
  isTimeLineShow: boolean;
  theme: 'dark' | 'light';
};

const initialState: ModalShow = {
  isSidebarShow: true,
  isTimeLineShow: true,
  theme: 'light',
};

export const layout = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    switchSidebarShow: (state) => {
      state.isSidebarShow = !state.isSidebarShow;
    },
    switchTimeLineShow: (state) => {
      state.isTimeLineShow = !state.isTimeLineShow;
    },
    setTimeLineShow: (state, action: PayloadAction<boolean>) => {
      state.isTimeLineShow = action.payload;
    },
    switchTheme: (state) => {
      state.theme = state.theme === 'dark' ? 'light' : 'dark';
    },
  },
});

export const {
  switchSidebarShow,
  switchTheme,
  switchTimeLineShow,
  setTimeLineShow,
} = layout.actions;

export default layout.reducer;
