import { Link, useLocation } from 'react-router-dom';

import { cn } from '@/shared/utils/utils';

import {
  active,
  addProj,
  iconWrapper,
  textInner,
  wrapper,
} from '@/widgets/dashboard-sidebar/blocks/NavLink/NavLink.css';

type Props = {
  icon: React.ReactNode;
  text: string;
  link?: string;
  onClick?: () => void;
  color?: string;
  hideText: boolean;
  testid?: string;
};

const NavLink = (props: Props) => {
  const { icon, text, link, onClick, color, hideText, testid } = props;
  const { pathname } = useLocation();

  const innerJSX = (
    <>
      <div className={iconWrapper}>{icon}</div>
      <span
        style={color ? { color } : undefined}
        hidden={hideText}
        className={textInner}
      >
        {text}
      </span>
    </>
  );

  if (onClick) {
    return (
      <button
        data-testid={testid}
        onClick={onClick}
        className={cn(wrapper, addProj)}
      >
        {innerJSX}
      </button>
    );
  }

  if (link) {
    return (
      <Link
        data-testid={testid}
        to={link}
        className={cn(wrapper, pathname?.startsWith(link) ? active : '')}
      >
        {innerJSX}
      </Link>
    );
  }

  return null;
};

export default NavLink;
