import { type MutableRefObject, useEffect, useState } from 'react';

import { useClickAway } from '@uidotdev/usehooks';
import { createPortal } from 'react-dom';
import { IoClose } from 'react-icons/io5';

import LogoIcon from '@/shared/ui/LogoIcon/LogoIcon';
import { customStyles } from '@/shared/ui/ModalWindow/constants';
import {
  closeButton,
  logoWrapper,
  relativeWrapper,
} from '@/shared/ui/ModalWindow/ModalWindow.css';
import { type Props } from '@/shared/ui/ModalWindow/types';

const ModalWindow = (props: Props) => {
  const {
    open,
    children,
    styles,
    zIndex,
    onClose,
    className,
    testId,
    hideClose,
  } = props;
  const [container] = useState<HTMLDivElement>(document.createElement('div'));
  const ref: MutableRefObject<HTMLDivElement> = useClickAway(() => {
    if (!open) return;
    onClose();
  });

  const closeOnEscapePressed = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    const containerPortal = document.getElementById('modal-portal-wrapper');
    if (!containerPortal || !open || !container) return;
    containerPortal.appendChild(container);
    return () => {
      containerPortal.removeChild(container);
    };
  }, [open, container]);

  useEffect(() => {
    if (!open) return;
    window.addEventListener('keydown', closeOnEscapePressed);
    return () => {
      window.removeEventListener('keydown', closeOnEscapePressed);
    };
  }, [open]);

  return createPortal(
    <div
      style={{
        ...customStyles.overlay,
        zIndex: zIndex ?? customStyles.overlay.zIndex,
      }}
    >
      <div
        style={{ ...customStyles.content, ...styles }}
        className={className}
        ref={ref}
        data-testid={testId}
      >
        {!hideClose && (
          <div className={relativeWrapper}>
            <button className={closeButton} onClick={onClose}>
              <IoClose />
            </button>
          </div>
        )}
        <div className={logoWrapper}>
          <LogoIcon />
        </div>

        {children}
      </div>
    </div>,
    container,
  );
};

export default ModalWindow;
