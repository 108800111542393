import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { useUserAuth } from '@/shared/hooks/useUserAuth.ts';
import { ETariffName } from '@/shared/types';

import {
  tariffInfo,
  tariffInfoContent,
} from '@/widgets/upload-video-modal/UploadVideoModal.css.ts';

const TariffInfo = () => {
  const { customer } = useUserAuth();
  const { t } = useTranslation();

  if (!customer?.customer) return;

  const {
    customer: {
      subscription: {
        availableMinutes,
        tariff: { name },
      },
    },
  } = customer;

  const duration = useMemo(() => {
    switch (name) {
      case ETariffName.FREE:
        return 1;
      case ETariffName.BASIC_MONTH:
      case ETariffName.BASIC_YEAR:
        return 10;
      case ETariffName.BUSINESS_MONTH:
      case ETariffName.BUSINESS_YEAR:
      case ETariffName.ENTERPRISE:
        return 30;
    }
  }, [name]);

  const size = useMemo(() => {
    switch (name) {
      case ETariffName.FREE:
        return 200;
      case ETariffName.BASIC_MONTH:
      case ETariffName.BASIC_YEAR:
        return 400;
      case ETariffName.BUSINESS_MONTH:
      case ETariffName.BUSINESS_YEAR:
        return 2000;
      case ETariffName.ENTERPRISE:
        return 5000;
    }
  }, [name]);

  const quality = useMemo(() => {
    switch (name) {
      case ETariffName.FREE:
      case ETariffName.BASIC_MONTH:
      case ETariffName.BASIC_YEAR:
        return `1080p ${t('subscriptions.fullHdVideo')}`;
      case ETariffName.BUSINESS_MONTH:
      case ETariffName.BUSINESS_YEAR:
        return `1440p (2K) ${t('subscriptions.video')}`;
      case ETariffName.ENTERPRISE:
        return `4K ${t('subscriptions.video')}`;
    }
  }, [name]);

  const speakers = useMemo(() => {
    switch (name) {
      case ETariffName.FREE:
        return 5;
      case ETariffName.BASIC_MONTH:
      case ETariffName.BASIC_YEAR:
        return 10;
      case ETariffName.BUSINESS_MONTH:
      case ETariffName.BUSINESS_YEAR:
        return 30;
      case ETariffName.ENTERPRISE:
        return 50;
    }
  }, [name]);

  return (
    <div className={tariffInfo}>
      <h4>
        {t('subscriptions.limits')} <span>{name}</span>{' '}
        {t('subscriptions.planLimits')}:
      </h4>
      <section className={tariffInfoContent}>
        <p>
          <MdKeyboardArrowRight />
          {duration} {t('subscriptions.minMaxDuration')}
        </p>
        <p>
          <MdKeyboardArrowRight />
          {t('subscriptions.video') + ' <'} {size}mb
        </p>
        <p>
          <MdKeyboardArrowRight />
          {speakers} {t('subscriptions.speakers')}
        </p>
        <p>
          <MdKeyboardArrowRight />
          {quality}
        </p>
      </section>
      <h4>
        {t('footerProfile.availableMins')}: <span>{availableMinutes}</span>
      </h4>
    </div>
  );
};

export default TariffInfo;
