import { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';

import { TOAST_ERROR } from '@/shared/constants/toasts.ts';
import Button from '@/shared/ui/Button';
import FormControl from '@/shared/ui/FormControl';

import { validLinks } from '@/widgets/upload-video-modal/constants';
import { IFormData } from '@/widgets/upload-video-modal/types';
import {
  formBtnSubmit,
  formLink,
  wrapperUploadLink,
} from '@/widgets/upload-video-modal/UploadVideoModal.css.ts';

type Props = {
  formData: IFormData;
  setFormData: Dispatch<SetStateAction<IFormData>>;
  setVideoUploaded: Dispatch<SetStateAction<boolean>>;
};

function containsCyrillic(str: string): boolean {
  const cyrillicPattern = /[А-Яа-яЁё]/;
  return cyrillicPattern.test(str);
}

const UploadByLink = ({ setVideoUploaded, formData, setFormData }: Props) => {
  const { t } = useTranslation();

  const uploadByLink = () => {
    if (!formData.videoLink) {
      TOAST_ERROR(t('uploadVideoModal.videoLink'));
      return;
    }
    setFormData({ ...formData, videoLink: formData.videoLink });
    setVideoUploaded(true);
  };

  const isDisabled = (value?: string): boolean => {
    const link = value || formData.videoLink;
    if (!link) return true;
    try {
      let count = 0;
      link.replace(/https?:\/\//g, (match) => {
        if (match === 'http://' || match === 'https://') {
          count++;
        }
        return match;
      });
      if (count !== 1) throw new Error(undefined);
      if (containsCyrillic(link)) throw new Error(undefined);

      const newUrl = new URL(link as string);
      const http = newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
      if (!http) throw new Error(undefined);
      let disable = true;
      for (const elem of validLinks) {
        if (link?.includes(elem)) {
          disable = false;
          break;
        }
      }
      if (disable) throw new Error(undefined);
      return false;
    } catch (err) {
      return true;
    }
  };

  return (
    <>
      <form className={formLink} id="upload project">
        <div style={{ flex: 1 }}>
          <FormControl
            label={t('uploadVideoModal.insertLink')}
            placeholder="YouTube, Instagram, TikTok, Google Disk or Yandex Disk"
            value={formData.videoLink}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, videoLink: e.target.value });
            }}
          />
        </div>
        <Button
          disabled={isDisabled()}
          type={'button'}
          variant={'outline'}
          onClick={uploadByLink}
          className={formBtnSubmit}
        >
          {t('uploadVideoModal.uploadLink')}
        </Button>
      </form>
      {isDisabled() && (
        <p className={wrapperUploadLink}>
          {t('uploadVideoModal.uploadLinkErr')}
        </p>
      )}
    </>
  );
};

export default UploadByLink;
