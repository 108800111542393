import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type Video = {
  currentTime: number;
  rewindTime: number | null;
  paused: boolean;
};

const initialState: Video = {
  currentTime: 0,
  rewindTime: null,
  paused: true,
};

export const video = createSlice({
  name: 'video',
  initialState,
  reducers: {
    setCurrentTime: (state, action: PayloadAction<number>) => {
      state.currentTime = action.payload;
    },
    setRewindTime: (state, action: PayloadAction<number | null>) => {
      state.rewindTime = action.payload;
    },
    setPaused: (state, action: PayloadAction<boolean>) => {
      state.paused = action.payload;
    },
  },
});

export const { setCurrentTime, setRewindTime, setPaused } = video.actions;
export default video.reducer;
