import { useEffect, useRef } from 'react';

const titles = [
  {
    type: 'Merlin',
    value: 'Merlin Clone',
  },
  {
    type: 'Synthar',
    value: 'Synthar',
  },
];

const PreloadResources = () => {
  const documentDefined = typeof document !== 'undefined';
  const originalTitle = useRef(documentDefined ? document.title : null);

  useEffect(() => {
    if (!documentDefined) return;
    const title = titles.find(
      (elem) => elem.type === import.meta.env.VITE_CONFIG_TYPE,
    )?.value;
    document.title = title ?? import.meta.env.VITE_CONFIG_TYPE;
    return () => {
      document.title =
        originalTitle.current ?? import.meta.env.VITE_CONFIG_TYPE;
    };
  }, []);

  return null;
};

export default PreloadResources;
