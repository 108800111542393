import { type ReactElement, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { MdExitToApp } from 'react-icons/md';

import { httpClient } from '@/shared/constants/apolloClients.ts';
import { useUserAuth } from '@/shared/hooks/useUserAuth';
import ConfirmationModal from '@/shared/ui/ConfirmationModal';
import { isMerlin } from '@/shared/utils/utils';
import { cn } from '@/shared/utils/utils';

import avatarIcon from '@/assets/user-logo-new.png';
import avatarIconSynthar from '@/assets/user-logo-synthar.png';

import {
  avatarImg,
  baseInfoContainer,
  logout,
  logoutShow,
  userName,
  userRole,
  wrapper,
  wrapperContainer,
  wrapperMins,
} from './FooterUserInfo.css';

type Props = {
  collapsed: boolean;
};

const FooterUserInfo: React.ElementType = ({
  collapsed,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const { user, deleteToken, deleteRefresh, setUser, customer } = useUserAuth();
  const [showLogOut, setShowLogOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showLogOutModal, setShowLogOutModal] = useState(false);

  const getMins = () => {
    if (
      !customer ||
      isNaN(Number(customer?.customer?.subscription?.availableMinutes))
    ) {
      return '?';
    }
    return customer?.customer?.subscription?.availableMinutes;
  };

  const handleLogOut = async () => {
    setIsLoading(true);
    await httpClient.clearStore();
    await httpClient.resetStore();
    deleteRefresh();
    deleteToken();
    setUser(null);
  };

  const styleVisibility: keyof typeof wrapperMins = collapsed
    ? 'hidden'
    : 'visible';

  return (
    <div className={wrapperContainer}>
      <div className={cn(wrapper, wrapperMins[styleVisibility])}>
        <p>
          <span>{t('footerProfile.availableMins')}:</span>
          <b>{getMins()}</b>
        </p>
      </div>

      <div
        className={wrapper}
        onMouseEnter={() => {
          setShowLogOut(true);
        }}
        onMouseLeave={() => {
          setShowLogOut(false);
        }}
      >
        <img
          width={45}
          height={45}
          src={isMerlin ? avatarIcon : avatarIconSynthar}
          alt={user?.username ?? '-'}
          className={avatarImg}
        />

        <div
          className={baseInfoContainer}
          style={{ opacity: collapsed ? 0 : 1 }}
        >
          <span className={userName}>{user?.username ?? '-'}</span>
          <span className={userRole}>{user?.email ?? '-'}</span>
          <span className={userRole}>
            {user?.verified
              ? t('footerProfile.verified')
              : t('footerProfile.unverified')}
          </span>
        </div>

        <button
          className={cn(logout, showLogOut ? logoutShow : '')}
          onClick={() => {
            setShowLogOutModal(true);
          }}
        >
          <MdExitToApp />
          {!collapsed && t('footerProfile.signOut')}
        </button>
      </div>

      <ConfirmationModal
        loading={isLoading}
        isOpen={showLogOutModal}
        onClose={() => {
          setShowLogOutModal(false);
        }}
        confirmFn={handleLogOut}
        title={t('footerProfile.titleLogOut')}
      />
    </div>
  );
};

export default FooterUserInfo;
