import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { BLOCKS_PER_PAGE } from '@/shared/constants';
import { type IAuthUser } from '@/shared/types';

type AuthUserSlice = {
  user: null | IAuthUser;
  refetchProjsValue: boolean;
  projectPage: number;
  blocksPerPage: number;
};

const initialState: AuthUserSlice = {
  user: null,
  refetchProjsValue: false,
  projectPage: 0,
  blocksPerPage: BLOCKS_PER_PAGE[0],
};

export const authUser = createSlice({
  name: 'authUser',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<null | IAuthUser>) => {
      state.user = action.payload;
    },
    setRefetchProjs: (state) => {
      state.refetchProjsValue = !state.refetchProjsValue;
    },
    setProjectPage: (state, action: PayloadAction<number>) => {
      state.projectPage = action.payload;
    },
    setBlocksPerPage: (state, action: PayloadAction<number>) => {
      state.blocksPerPage = action.payload;
    },
  },
});

export const { setUser, setRefetchProjs, setProjectPage, setBlocksPerPage } =
  authUser.actions;
export default authUser.reducer;
