import { gql } from '@apollo/client';

export const CHANGE_LANGUAGE = gql`
  mutation changeLanguage($input: ChangeLanguageInput!) {
    changeLanguage(input: $input) {
      __typename
      ... on ChangeLanguageSuccess {
        success
      }
      ... on InvalidLanguageError {
        message
      }
      ... on GeneralError {
        message
      }
    }
  }
`;
