import {
  type Dispatch,
  type FormEvent,
  type MutableRefObject,
  type SetStateAction,
  useState,
} from 'react';

import { useClickAway } from '@uidotdev/usehooks';
import { useTranslation } from 'react-i18next';
import { IoClose } from 'react-icons/io5';
import { TiUserAddOutline } from 'react-icons/ti';

import VIcon from '@/assets/v.svg?react';
import {
  addBlock,
  btns,
  btnsSvg,
  inputContainer,
  line,
  menu,
  placeholder,
  selectedBlock,
  selectedUsers,
  userActive,
  userBlock,
  usersContainer,
  userSelected,
  wrapper,
} from '@/features/select-users/SelectUsers.css';

export type SelectedUser = {
  email: string;
  username: string;
};

interface IUserDataItem extends SelectedUser {
  id: string;
  isActive: boolean;
}

interface ISelectUsers {
  selected: SelectedUser[] | null;
  setSelected: Dispatch<SetStateAction<SelectedUser[] | null>>;
  data: IUserDataItem[] | undefined;
}

const SelectUsers = ({ selected, setSelected, data }: ISelectUsers) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [query, setQuery] = useState('');
  const ref: MutableRefObject<HTMLDivElement> = useClickAway(() => {
    setShow(false);
  });

  const handleAdd = (value: SelectedUser) => {
    if (selected?.find((elem) => elem.email === value.email)) {
      handleDelete(value);
      return;
    }
    if (selected) {
      setSelected([
        ...selected,
        { username: value.username, email: value.email },
      ]);
    } else {
      setSelected([{ username: value.username, email: value.email }]);
    }
  };

  const handleDelete = (value: SelectedUser) => {
    if (!selected?.length || selected.length === 1) {
      setSelected(null);
    } else {
      setSelected(selected.filter((elem) => elem.email !== value.email));
    }
  };

  const handleAddInput = (e: FormEvent) => {
    e.preventDefault();
    if (!query || !data) return;
    const user = data?.find(
      (elem) => elem.email.toLowerCase() === query.toLowerCase(),
    );
    if (!user) {
      handleAdd({
        email: query.toLowerCase(),
        username: '-',
      });
      setQuery('');
    } else {
      handleAdd(user);
      setQuery('');
    }
  };

  if (!data)
    return (
      <div className={wrapper}>
        <div className={selectedBlock}>
          <label>{t('selectUsers.addPeople')}</label>

          <div className={selectedUsers}>
            <p className={placeholder}>{t('general.loading')}</p>
          </div>

          <div className={btns}>
            <IoClose className={btnsSvg} fontSize={20} />
            <div className={line} />
            <VIcon className={btnsSvg} />
          </div>
        </div>
      </div>
    );

  return (
    <div className={wrapper} ref={ref}>
      <div className={selectedBlock}>
        <label>{t('selectUsers.addPeople')}</label>

        <div
          className={selectedUsers}
          onClick={() => {
            setShow((prev) => !prev);
          }}
        >
          {selected?.length ? (
            selected.map((elem) => (
              <div className={userSelected} key={elem.email}>
                <p>{elem.email}</p>
                <IoClose
                  fontSize={18}
                  onClick={() => {
                    handleDelete(elem);
                  }}
                />
              </div>
            ))
          ) : (
            <p className={placeholder}>{t('selectUsers.selectUsers')}</p>
          )}
        </div>

        <div className={btns}>
          <IoClose
            fontSize={20}
            className={btnsSvg}
            onClick={() => {
              setSelected(null);
            }}
          />
          <div className={line} />
          <VIcon
            className={btnsSvg}
            onClick={() => {
              setShow((prev) => !prev);
            }}
          />
        </div>
      </div>

      {show && (
        <div className={menu}>
          <form
            className={inputContainer}
            onSubmit={handleAddInput}
            id="select users"
          >
            <label>{t('selectUsers.userEmail')}</label>
            <input
              required
              type={'email'}
              placeholder={t('selectUsers.findUsers')}
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
            />
            <button className={addBlock} type={'submit'}>
              <TiUserAddOutline />
              <p>{t('selectUsers.invite')}</p>
            </button>
          </form>

          <div className={usersContainer}>
            {data
              .filter((node) => node.email.includes(query))
              .filter(
                (node) => !selected?.find((elem) => elem.email === node.email),
              )
              .map((node) => (
                <div
                  key={node.id}
                  className={userBlock}
                  onClick={() => {
                    handleAdd(node);
                  }}
                >
                  <p>{node.email}</p>
                  <small
                    className={
                      selected?.find((elem) => elem.email === node.email)
                        ? userActive
                        : ''
                    }
                  >
                    {node?.isActive
                      ? t('selectUsers.added')
                      : t('selectUsers.add')}
                  </small>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectUsers;
