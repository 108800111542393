import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import Button from '@/shared/ui/Button';
import LogoIcon from '@/shared/ui/LogoIcon/LogoIcon.tsx';
import ModalWindow from '@/shared/ui/ModalWindow';

import { setShowUpdate } from '@/app/store/features/modalShow.ts';
import { useAppDispatch } from '@/app/store/redux-hooks.ts';
import {
  footer,
  image,
  noImage,
  text,
  title,
  wrapper,
} from '@/features/updates-modal/UpdatesModal.css.ts';

type Elem = {
  idx: number;
  text: string;
  image?: string;
  isHide?: boolean;
};
const data: Elem[] = [
  { idx: 0, text: 'updates1', image: '1-update.gif' },
  { idx: 1, text: 'updates3', image: '2-update.gif' },
  { idx: 2, text: 'updates4', image: '3-update.gif' },
  { idx: 3, text: 'updates5', image: '4-update.gif' },
  { idx: 4, text: 'updates6' },
  { idx: 5, text: 'updates7', image: '5-update.gif' },
];

const UpdatesModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showItem, setShowItem] = useState<Elem>(data[0]);

  const onCLose = () => dispatch(setShowUpdate(false));

  return (
    <ModalWindow
      open
      onClose={onCLose}
      className={wrapper}
      styles={{ width: 560, padding: 30, gap: 0 }}
    >
      <h3 className={title}>{t('tours.updatesTitle')}</h3>
      <p className={text}>{t('tours.' + showItem.text)}</p>
      {showItem?.image ? (
        <img
          className={image}
          alt=""
          src={
            `/updates-${import.meta.env.VITE_CONFIG_TYPE?.toLowerCase()}/` +
            showItem.image
          }
        />
      ) : (
        <div className={`${image} ${noImage}`}>
          <LogoIcon />
          <p>{t('tours.noPhotoInSlide')}</p>
        </div>
      )}

      <footer className={footer}>
        <Button
          style={{ flex: 0.5 }}
          disabled={showItem.idx === 0}
          onClick={() => setShowItem(data[showItem.idx - 1])}
          variant="outline"
        >
          <IoIosArrowBack />
          {t('tours.prevUpdate')}
        </Button>
        <Button
          style={{ flex: 0.5 }}
          disabled={showItem.idx === data.length - 1}
          onClick={() => setShowItem(data[showItem.idx + 1])}
        >
          {t('tours.nextUpdate')}
          <IoIosArrowForward />
        </Button>
      </footer>
    </ModalWindow>
  );
};

export default UpdatesModal;
