import { gql } from '@apollo/client';

export const REFRESH_TOKEN = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(input: { refreshToken: $refreshToken }) {
      #   payload
      success
      errors
      token {
        token
      }
      refreshToken {
        token
      }
    }
  }
`;
