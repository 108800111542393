import { useTranslation } from 'react-i18next';

import LoaderWithText from '@/shared/ui/LoaderWithText';

import {
  create,
  loadContainer,
} from '@/widgets/upload-video-modal/UploadVideoModal.css';

const LoadingWrapper = () => {
  const { t } = useTranslation();

  return (
    <div className={loadContainer}>
      <header>
        <LoaderWithText hideText />
        <h4 className={create}>{t('uploadVideoModal.uploading')}</h4>
      </header>
      <p>{t('uploadVideoModal.uploadTextLoad')}</p>
    </div>
  );
};

export default LoadingWrapper;
