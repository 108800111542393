import { StrictMode } from 'react';

import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import '@/app/i18n/i18n.ts';
import { ExternalLibraries } from '@/app/providers/external-libraries.tsx';
import PreloadResources from '@/app/providers/preload-resources.tsx';
import { Providers } from '@/app/providers/providers.tsx';
import Router from '@/app/router/router.tsx';
import Error from '@/pages/error';

Sentry.init({
  dsn: import.meta.env.PROD ? import.meta.env.VITE_SENTRY_DSN : '',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  release: import.meta.env.VITE_SENTRY_RELEASE,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const appRoot = document.getElementById('root')!;
appRoot.setAttribute('notranslate', 'true');

window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});

createRoot(appRoot).render(
  <StrictMode>
    <ExternalLibraries />
    <PreloadResources />
    <Providers>
      <Sentry.ErrorBoundary fallback={({ error }) => <Error error={error} />}>
        <Router />
      </Sentry.ErrorBoundary>
    </Providers>
  </StrictMode>,
);
