import {
  input,
  label as labelStyle,
  wrapper,
} from '@/shared/ui/FormControl/FormControl.css';
import { cn } from '@/shared/utils/utils';

interface Props extends Textarea {
  label?: string;
}

type Textarea = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

const FormTextarea = (props: Props) => {
  const { label, ...rest } = props;

  return (
    <div className={wrapper}>
      {label && <label className={labelStyle}>{label}</label>}
      <textarea className={cn(input, rest.className)} {...rest} />
    </div>
  );
};

export default FormTextarea;
