import { Suspense, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useIntercom } from '@/shared/hooks/useIntercom.ts';
import { useNotifications } from '@/shared/hooks/useNotification';
import { useUserAuth } from '@/shared/hooks/useUserAuth';

import { useAppSelector } from '@/app/store/redux-hooks';
import { vars } from '@/app/styles/contract.css.ts';
import SkeletonProjects from '@/pages/projects/skeleton.tsx';
import DashboardSidebar from '@/widgets/dashboard-sidebar';
import DashboardSidebarSkeleton from '@/widgets/dashboard-sidebar/DashboardSidebarSkeleton';

import { container, layoutRoot, main } from './styles.css';

export default function SidebarLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  useNotifications();
  const { user, refreshToken, token, updateUser } = useUserAuth();
  const navigate = useNavigate();
  const isSidebarShow = useAppSelector((state) => state.layout.isSidebarShow);

  useEffect(() => {
    if (!refreshToken || !token) {
      navigate(`/sign-in`);
    }
  }, [refreshToken, token, user]);

  useEffect(() => {
    if (!token || user) return;
    updateUser();
  }, [token, user]);

  useIntercom();

  if (!user) {
    return (
      <div
        style={{
          display: 'flex',
          background: vars.color.defaultBg,
          overflowY: 'hidden',
        }}
      >
        <DashboardSidebarSkeleton />
        <div style={{ padding: '0 50px', flex: 1 }}>
          <SkeletonProjects />
        </div>
      </div>
    );
  }

  return (
    <div className={layoutRoot}>
      {/* sidebar with fallback */}
      <Suspense fallback={<DashboardSidebarSkeleton />}>
        <DashboardSidebar />
      </Suspense>

      <div
        className={container}
        style={
          isSidebarShow
            ? { width: 'calc(100% - 256px)' }
            : { width: 'calc(100% - 85px)' }
        }
      >
        <main className={main}>{children}</main>
      </div>
    </div>
  );
}
