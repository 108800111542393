import { CSSProperties } from 'react';

import { wrapper } from '@/shared/ui/Skeleton/Skeleton.css';

import { vars } from '@/app/styles/contract.css.ts';

type Props = {
  width?: string;
  height?: string;
  pill?: boolean;
  styles?: CSSProperties;
};

const Skeleton = ({ width, height, pill, styles }: Props) => {
  return (
    <div
      className={wrapper}
      style={{
        width: width ?? '100%',
        height: height ?? '1em',
        borderRadius: pill ? 100 : 3,
        background: vars.color.skeletonColor,
        ...styles,
      }}
    />
  );
};

export default Skeleton;
