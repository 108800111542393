import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

type ModalShow = {
  showCreate: {
    isShow: boolean;
    isAvatar?: boolean;
  };
  showClone: boolean;
  showUpdate: boolean;
};

const initialState: ModalShow = {
  showCreate: {
    isShow: false,
    isAvatar: false,
  },
  showClone: false,
  showUpdate: false,
};

export const modalShow = createSlice({
  name: 'modalShow',
  initialState,
  reducers: {
    setShowCreate: (state, action: PayloadAction<ModalShow['showCreate']>) => {
      state.showCreate = action.payload;
    },
    setShowClone: (state, action: PayloadAction<boolean>) => {
      state.showClone = action.payload;
    },
    setShowUpdate: (state, action: PayloadAction<boolean>) => {
      state.showUpdate = action.payload;
    },
  },
});

export const { setShowCreate, setShowClone, setShowUpdate } = modalShow.actions;
export default modalShow.reducer;
