import { useEffect } from 'react';

import { useUserAuth } from '@/shared/hooks/useUserAuth.ts';
import { convertDateTime } from '@/shared/utils/convertDateTime.ts';

// @ts-expect-error-kek
console.log(window?.Intercom?.updateUser);

export const useIntercom = () => {
  const { user, customer } = useUserAuth();
  useEffect(() => {
    // @ts-expect-error - Intercom need to be available in window
    if (!user || !import.meta.env.PROD || !window?.Intercom) return;
    // @ts-expect-error - Intercom need to be available in window
    window?.Intercom('update');
    // @ts-expect-error - Intercom need to be available in window
    window?.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: import.meta.env.VITE_INTERCOM_APP_ID,
      email: user?.email, // the email for your user
      utm_content:
        customer?.customer?.subscription?.tariff?.name +
        ` (${customer?.customer?.subscription?.availableMinutes}mins)`,
      utm_term: `Last paid: ${convertDateTime(customer?.customer?.bills?.edges?.[0]?.node?.datePurchased)}; Next paid: ${convertDateTime(customer?.customer?.bills?.edges?.[0]?.node?.expirationDate)}`,
    });
  }, [user, customer]);
};
