import { useTranslation } from 'react-i18next';
import { FiUser } from 'react-icons/fi';
import { GrProjects } from 'react-icons/gr';
import { MdMenuOpen, MdOutlineMenu } from 'react-icons/md';
import { TbCreditCardPay } from 'react-icons/tb';
import { Link } from 'react-router-dom';

import { isMerlin } from '@/shared/utils/utils';

import { switchSidebarShow } from '@/app/store/features/layout';
import { useAppDispatch, useAppSelector } from '@/app/store/redux-hooks';
import LogoImgClosed from '@/assets/logo-closed.svg?react';
import LogoSyntharImgClosed from '@/assets/logo-synthar-closed.svg?react';
import LogoSyntharImg from '@/assets/logo-synthar.svg?react';
import LogoImg from '@/assets/logo.svg?react';
import UpdatesModal from '@/features/updates-modal';
import FooterUserInfo from '@/widgets/dashboard-sidebar/blocks/FooterUserInfo/FooterUserInfo';
import NavLink from '@/widgets/dashboard-sidebar/blocks/NavLink';
import {
  collapsedButton,
  footerSidebar,
  logo,
  navWrapper,
  sidebarHeader,
  wrapper,
} from '@/widgets/dashboard-sidebar/DashboardSidebar.css';
import UploadVideoModal from '@/widgets/upload-video-modal/UploadVideoModal';

const DashboardSidebar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isSidebarShow = useAppSelector((state) => state.layout.isSidebarShow);
  const isUpdatesShow = useAppSelector((state) => state.modalShow.showUpdate);
  const isUploadShow = useAppSelector(
    (state) => state.modalShow.showCreate?.isShow,
  );

  return (
    <div className={wrapper} style={{ width: isSidebarShow ? 256 : 85 }}>
      <div
        className={sidebarHeader}
        style={{
          justifyContent: isSidebarShow ? 'space-between' : 'center',
        }}
      >
        <Link
          to={'/dashboard/projects'}
          className={logo}
          data-testid="sidebar-logo"
        >
          {isMerlin ? (
            isSidebarShow ? (
              <LogoImg />
            ) : (
              <LogoImgClosed />
            )
          ) : isSidebarShow ? (
            <LogoSyntharImg />
          ) : (
            <LogoSyntharImgClosed />
          )}
        </Link>

        <button
          className={collapsedButton}
          onClick={() => dispatch(switchSidebarShow())}
          onKeyDown={(e) => {
            if (e.key === 'Enter') dispatch(switchSidebarShow());
          }}
        >
          {isSidebarShow ? <MdMenuOpen /> : <MdOutlineMenu />}
        </button>
      </div>

      <nav className={navWrapper}>
        <NavLink
          link={'/dashboard/projects'}
          text={t('menu.projects')}
          hideText={!isSidebarShow}
          icon={<GrProjects style={{ transform: 'scale(0.8)' }} />}
          testid="projects-link"
        />

        <NavLink
          link={'/dashboard/avatar-projects'}
          text={t('menu.avatars')}
          hideText={!isSidebarShow}
          icon={<FiUser />}
          testid="avatar-projects-link"
        />

        <NavLink
          link={'/subscriptions/overview'}
          text={t('menu.subscriptions')}
          hideText={!isSidebarShow}
          icon={<TbCreditCardPay />}
          testid="subscriptions-link"
        />
      </nav>

      <div className={footerSidebar}>
        <FooterUserInfo collapsed={!isSidebarShow} />
      </div>

      {/* modal for video uploading */}
      {isUploadShow && <UploadVideoModal />}
      {/* modal with last updates-merlin */}
      {isUpdatesShow && <UpdatesModal />}
    </div>
  );
};

export default DashboardSidebar;
