import type { CSSProperties } from 'react';

import Button from '@/shared/ui/Button';
import LogoIcon from '@/shared/ui/LogoIcon/LogoIcon.tsx';

import { vars } from '@/app/styles/contract.css.ts';

type Props = {
  error: unknown;
};

const styles: Record<string, CSSProperties> = {
  wrapper: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: vars.color.defaultBg,
    color: vars.color.defaultText,
  },
  span: {
    lineHeight: 1.5,
    fontSize: 80,
    fontWeight: 700,
  },
  text: {
    width: '75%',
    maxWidth: 700,
    marginBottom: 30,
    fontWeight: 400,
    fontSize: 18,
    textAlign: 'center',
  },
  button: {
    width: 300,
    height: 50,
  },
};

const Error = ({ error }: Props) => {
  const onReload = () => {
    window.location.reload();
  };

  return (
    <div style={styles.wrapper}>
      <LogoIcon />
      <span style={styles.span}>Oops error!</span>
      <h4 style={styles.text}>
        {error?.toString()}
        <br /> <br />
        <br />
        We were unable to process this error, you can try reloading the page,
        and if this does not help, we recommend clearing your browser cache and
        writing to us in support, thank you for your understanding!
      </h4>

      <Button style={styles.button} onClick={onReload}>
        Refresh the page
      </Button>
    </div>
  );
};

export default Error;
