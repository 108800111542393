import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';

import { TOAST_WARNING } from '@/shared/constants/toasts';
import { useUserAuth } from '@/shared/hooks/useUserAuth';
import { ETariffName } from '@/shared/types';
import Button from '@/shared/ui/Button';

import { setShowCreate } from '@/app/store/features/modalShow.ts';
import { useAppDispatch } from '@/app/store/redux-hooks.ts';
import { acceptTypes, fileTypes } from '@/widgets/upload-video-modal/constants';
import { useUploadVideoUtils } from '@/widgets/upload-video-modal/hooks/useUploadVideoUtils';
import { type IUploadVideoProps } from '@/widgets/upload-video-modal/types';
import TariffInfo from '@/widgets/upload-video-modal/ui/TariffInfo.tsx';
import UploadByLink from '@/widgets/upload-video-modal/ui/UploadByLink.tsx';
import {
  create,
  disableAvatarText,
  dragContainer,
  dragTypes,
  or,
  uploadContainer,
} from '@/widgets/upload-video-modal/UploadVideoModal.css';

const UploadVideo = (props: IUploadVideoProps) => {
  const {
    setVideoParams,
    setVideo,
    setVideoUploaded,
    formData,
    setFormData,
    avatar,
  } = props;

  const dispatch = useAppDispatch();
  const { customer } = useUserAuth();
  const { t } = useTranslation();
  const { uploadDragAndDrop } = useUploadVideoUtils(
    setVideo,
    setVideoParams,
    setVideoUploaded,
    (title: string) => {
      setFormData({ ...formData, title });
    },
  );

  if (
    avatar &&
    customer?.customer?.subscription?.tariff?.name === ETariffName.FREE
  ) {
    return (
      <>
        <p className={disableAvatarText}>
          {t('uploadVideoModal.avatarDisabled')}
        </p>
        <Button
          style={{ width: 220 }}
          onClick={() => dispatch(setShowCreate({ isShow: false }))}
        >
          {t('general.cancel')}
        </Button>
      </>
    );
  }

  return (
    <div className={uploadContainer}>
      <h3 className={create}>{t('uploadVideoModal.videoUpload')}</h3>

      <FileUploader
        handleChange={uploadDragAndDrop}
        name="file"
        types={fileTypes.split(', ')}
        multiple={false}
        accept={acceptTypes}
        onTypeError={() =>
          TOAST_WARNING(
            `${t('uploadVideoModal.invalidFileFormat')}: ${fileTypes}`,
          )
        }
      >
        <div className={dragContainer}>
          <p className={dragTypes} style={{ margin: 0 }}>
            {t('uploadVideoModal.dragAndDropHere')} <br />
            {t('uploadVideoModal.supportedTypes')} {fileTypes}
          </p>
        </div>
      </FileUploader>

      <p className={or}>- {t('general.or')} -</p>

      <UploadByLink
        formData={formData}
        setVideoUploaded={setVideoUploaded}
        setFormData={setFormData}
      />
      <TariffInfo />
    </div>
  );
};

export default UploadVideo;
