import { gql } from '@apollo/client';

export const AVAILABLE_LANGUAGES = gql`
  query availableLanguages {
    availableLanguages {
      name
      code
    }
  }
`;
